.no-found-main {
    text-align: center;
    margin: 120px 0;
    h1 {
        margin: 0 0 20px;
        font-size: 100px;
        line-height: normal;
        color: #20386a;
    }
    h4 {
        margin: 0;
        color: #20386a;
    }
    p{
        color: #20386a;
    }
}

@media screen and (max-width:767px){
    .no-found-main{
        margin: 80px 0;
        h1 {
            font-size: 80px;
        }
        h4 {
            font-size: 18px;
        }
    }
}