.page-layout-2columns-left {
    .sidebar-main {
        width: 24.27%;
        padding-right: 16px;
        .side-color {
            background: #637497;
            border-radius: 10px 10px 0px 0px;
            .block-subtitle {
                border-bottom: none;
                color: #fff;
                font-weight: 500;
                font-size: 24px;
                line-height: 37px;
                font-family: $font_khandmedium;
                padding: 6px 5px 5px 35px;
            }
        }
        .gray-back {
            background: #E9EBF0;
            border-radius: 0 0 10px 10px;
            padding: 0 35px;
            margin-bottom: 25px;
            .filter-actions {
                margin: 0px;
                padding-top: 10px;
                padding-bottom: 20px;
                .filter-clear {
                    color: #20386A;
                    font-size: 16px;
                    font-family: $font_khandregular;
                }
            }
            .fliter-cat {
                padding-top: 24px;
                padding-bottom: 18px;
                .current-cat {
                    color: #515050;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    font-family: $font_khandmedium;
                }
            }
            .filter-current {
                border-top: 1px solid #D0D2D9;
                .filter-current-subtitle {
                    color: #20386A;
                    font-size: 16px;
                    font-family: $font_khandbold;
                    padding-top: 20px;
                    padding-bottom: 10px;
                }
                .items {
                    padding: 0px;
                    .item {
                        background-color: #9B9FAA;
                        border-radius: 12px;
                        margin: 8px 0;
                        font-size: 14px;
                        line-height: 21px;
                        color: #FFFFFF;
                        font-family: $font_khandmedium;
                        padding: 3px 18px 0;
                        .filter-value {
                            font-size: 14px;
                            line-height: 21px;
                            color: #FFFFFF;
                            font-family: $font_khandmedium;
                        }
                        .action {
                            &.remove {
                                right: 11px;
                                left: inherit;
                                &:before {
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .amshopby-filter-name {
                            font-weight: normal;
                        }
                        .amshopby-remove {
                            left: auto;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                            &:before,
                            &:after {
                                display: block;
                                background-color: #FFFFFF;
                                height: 2px;
                            }
                        }
                    }
                }
            }
        }
        .am-filter-items-attr_category_ids{
            li {
                padding: 0;
                input.am-input{
                    display: none;
                }
            }
        }
        .filter-options {
            padding: 0 28px;
        }
        .filter-options-title {
            color: #20386A;
            font-size: 16px;
            line-height: 24px;
            font-family: $font_khandbold;
            font-weight: normal;
            padding-bottom: 12px;
            padding-bottom: 8px;
            padding-left: 5px;
            border-bottom: 1px solid #D0D2D9;
            text-transform: none;
            &:after {
                content: none;
            }
        }
        .filter-options-content {
            padding: 5px 0px 15px 0px;
            li {
                margin: 9px 0px;
                a {
                    color: #515050;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $font_khandregular;
                    padding: 0 5px;
                    margin: 0;
                    &:hover {
                        background: none;
                        color: #20386a;
                    }
                    &.am_shopby_link_selected {
                        font-weight: normal;
                        font-family: $font_khandmedium;
                    }
                }
            }
        }
    }
    .column {
        &.main {
            width: 75.7%;
            padding-left: 16px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .page-layout-2columns-left {
        .sidebar-main {
            width: 30.27%;
        }
    }
}

@media only screen and (max-width: 991px) {
    .page-layout-2columns-left {
        .sidebar-main {
            width: 33.27%;
            .side-color {
                .block-subtitle {
                    padding-left: 20px;
                }
            }
            .gray-back {
                padding: 0 20px;
            }
            .filter-options {
                padding: 0 20px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .page-layout-2columns-left {
        .sidebar-main {
            width: 100%;
            padding: 0px;
            .filter-options {
                padding: 0px;
            }
            .filter-options-title {
                border-bottom: none;
                color: #262424;
                text-align: center;
                font-size: 20px;
                line-height: 58px;
                font-family: $font_khandsemibold;
                padding-right: 0px;
            }
            .filter-options-content {
                padding-bottom: 0px;
                li {
                    background: #E9EBF0;
                    border-radius: 56px;
                    a {
                        color: #20386A;
                        font-size: 24px;
                        line-height: 30px;
                        font-family: $font_khandlight;
                        padding-top: 19px;
                        padding-bottom: 18px;
                        display: flex;
                        padding-left: 41px;
                        .count {
                            display: none;
                        }
                    }
                }
            }
            .gray-back {
                background: none;
                padding: 0px;
                display: none;
                .fliter-cat {
                    padding-top: 19px;
                    padding-bottom: 18px;
                    background-color: #20386A;
                    border-radius: 56px;
                    margin-bottom: 0;
                    margin-top: 20px;
                    padding-left: 41px;
                    .current-cat {
                        color: #E9EBF0;
                        font-size: 24px;
                        line-height: 30px;
                        font-family: $font_khandregular;
                    }
                }
                .filter-current {
                    border: none;
                    .filter-current-subtitle {
                        display: none;
                    }
                    .items {
                        display: block;
                    }
                }
            }
        }
    }
}