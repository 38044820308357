.home_specific_parts_section {
    background-color: #E9EBF0;
    padding: 70px 0px;
}

.home-specific-parts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .btn-section {
        max-width: 280px;
        width: 100%;
        .btn {
            background-color: #FFBE5F;
            border-radius: 28px;
            color: #262424;
            font-size: 20px;
            line-height: 24px;
            display: block;
            padding: 15px 10px;
            width: 100%;
            text-align: center;
            text-decoration: none;
            margin-top: 15px;
            transition: all ease 0.5s;
            &:hover {
                background-color: #20386A;
                color: #fff;
            }
        }
    }
    .left-part {
        max-width: 450px;
        //width: 100%;
        width: 35%;
        .title {
            color: #20386A;
            font-size: 40px;
            line-height: 50px;
            font-family: $font_khandmedium;
            max-width: 320px;
        }
        .text {
            color: #262424;
            font-size: 20px;
            line-height: 58px;
            font-family: $font_primary;
        }
    }
    .right-part {
        width: 65%;
        .item-list {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            .item {
                border: 1px solid #D9D9DB;
                border-radius: 5px;
                padding: 34px 14px 42px;
                margin: 0px 2px;
                height: 100%;
                //min-width: 163px;
                width: calc(20% - 4px);
                min-height: 255px;
                .item-inner {
                    height: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                    .step {
                        margin-bottom: auto;
                        color: #262424;
                        font-size: 20px;
                        line-height: 20px;
                        text-transform: uppercase;
                        font-family: $font_khandregular;
                    }
                    .image {
                        margin: 10px 0px;
                    }
                    .title {
                        margin-top: auto;
                        color: #20386A;
                        font-size: 14px;
                        line-height: 20px;
                        text-transform: uppercase;
                        font-family: $font_open_sansbold;
                    }
                }
                .mobile-image {
                    display: none;
                }
            }
        }
    }
    .mobile-btn {
        display: none;
        width: 100%;
    }
}

@media only screen and (max-width: 1199px) {
    .home-specific-parts {
        .btn-section {
            .btn {
                margin-top: 0;
            }
        }
        .left-part {
            max-width: 100%;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .content {
                padding-right: 25px;
            }
        }
        .right-part {
            width: 100%;
            margin-top: 50px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .home_specific_parts_section {
        padding: 55px 0;
    }
    .home-specific-parts {
        .btn-section {
            display: none;
        }
        .mobile-btn {
            display: block;
            margin-top: 34px;
            .btn-section {
                margin: 0px auto;
                display: block;
            }
        }
        .left-part {
            justify-content: center;
            text-align: center;
            .content {
                padding-right: 0;
                .title {
                    margin: 0px auto;
                }
            }
        }
        .right-part {
            .item-list {
                flex-direction: column;
                height: auto;
                .item {
                    width: 100%;
                    margin: 0px 0px 8px;
                    min-height: auto;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    padding: 18px;
                    .mobile-image {
                        width: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .item-inner {
                        width: 50%;
                        .image {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 520px) {
    .home-specific-parts {
        .left-part {
            .title {
                margin: 0px auto;
            }
            .text {
                line-height: 30px;
            }
        }
        .right-part {
            .item-list {
                .item {
                    justify-content: space-between;
                    .item-inner {
                        width: auto;
                        justify-content: flex-start;
                        text-align: left;
                        padding-right: 20px;
                        .step {
                            width: 100%;
                            margin-bottom: 5px;
                        }
                        .title {
                            margin-bottom: 0px;
                        }
                    }
                    .mobile-image {
                        width: auto;
                        justify-content: flex-end;
                        .image {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}