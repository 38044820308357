.breadcrumbs-main {
    background-color: #E9EBF0;
    padding: 13px 0;
    .breadcrumbs {
        max-width: 100%;
        margin: 0;
        padding: 0;
        .items {
            display: flex;
            flex-wrap: wrap;
            .item {
                position: relative;
                a,
                strong {
                    font-family: $font_khandmedium;
                    font-size: 14px;
                    line-height: 1.7143;
                }
                a {
                    color: #909090;
                    text-decoration: none;
                    &:hover {
                        color: #20386A;
                    }
                }
                strong {
                    color: #20386A;
                }
                &:not(:last-child) {
                    padding-right: 20px;
                    &:after {
                        margin: 0;
                        background-color: #909090;
                        display: block;
                        content: "";
                        width: 1px;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

@media all and (max-width:767px) {
    .breadcrumbs-main {
        display: none;
    }
}