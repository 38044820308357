html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.container,
.big-container,
body .navigation,
// body .breadcrumbs,
body .page-header .header.panel,
body .header.content,
body .footer.content,
body .page-wrapper>.widget,
body .page-wrapper>.page-bottom,
body .block.category.event,
body .top-container,
body .page-main,
.banner-container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

.small-container {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
}

body.cms-index-index .page-main {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

@media (min-width: 480px) {
    .container,
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main,
    .banner-container {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media (min-width: 576px) {
    .container,
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main,
    .banner-container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .container,
    body .navigation, // body .breadcrumbs,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main {
        max-width: 100%;
        padding-right: 30px;
        padding-left: 30px;
    }
    .big-container,
    body .page-header .header.panel,
    body .header.content,
    .banner-container {
        max-width: 100%;
    }
    .small-container {
        max-width: 100%;
    }
}

@media (min-width: 992px) {
    .container,
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main,
    .banner-container {
        max-width: 900px;
    }
    .small-container {
        max-width: 898px;
    }
}

@media (min-width: 1200px) {
    .container,
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main,
    .banner-container {
        max-width: 1150px;
    }
    body.catalog-product-view .page-main {
        max-width: 1150px;
    }
}

@media (min-width: 1376px) {
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main {
        max-width: 1332px;
    }
    .banner-container {
        max-width: 1230px;
    }
}

@media (min-width: 1440px) {
    .big-container,
    body .navigation, // body .breadcrumbs,
    body .page-header .header.panel,
    body .header.content,
    body .footer.content,
    body .page-wrapper>.widget,
    body .page-wrapper>.page-bottom,
    body .block.category.event,
    body .top-container,
    body .page-main {
        max-width: 1375px;
    }
}

body {
    font-family: $font_primary;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #262424;
    letter-spacing: normal;
    p {
        font-size: 16px;
        line-height: 26px;
        color: #262424;
        margin: 0 0 20px;
    }
    a {
        font-family: $font_primary;
        font-size: 16px;
        line-height: 26px;
        color: #84B0F1;
        transition: all ease .4s;
        &:hover {
            color: #515050;
            text-decoration: none;
        }
    }
    .action.primary,
    button:not(.primary) {
        font-weight: normal;
        background: #FFBE5F;
        border-radius: 27.5px;
        border: 0;
        font-family: $font_khandregular;
        font-weight: normal;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #262424;
        transition: all ease .4s;
        &:hover,
        &:focus {
            background-color: #20386a;
            color: #fff;
            border: 0;
        }
    }
    button:not(.primary) {
        box-shadow: none;
        border-radius: 0 !important;
        padding: 4px 20px;
    }
    ._keyfocus *:focus,
    input:not([disabled]):focus,
    textarea:not([disabled]):focus,
    select:not([disabled]):focus {
        box-shadow: none;
    }
    h1 {
        font-weight: normal;
        font-size: 48px;
        line-height: 76px;
        color: #000000;
    }
    h2 {
        font-weight: bold;
        font-size: 36px;
        line-height: 55px;
        color: #000000;
    }
    h3 {
        font-weight: normal;
        font-size: 36px;
        line-height: 54px;
        color: #000000;
    }
    h4 {
        font-weight: bold;
        font-size: 24px;
        line-height: 43px;
        color: #000000;
    }
    h5 {
        font-weight: normal;
        font-size: 24px;
        line-height: 56px;
        color: #000000;
    }
    h6 {
        font-weight: bold;
        font-size: 20px;
        line-height: 33px;
        color: #000000;
    }
}

.cms-home {
    .columns {
        .column.main {
            padding-bottom: 0;
        }
    }
}

@media screen and (max-width:767px) {
    body {
        h1 {
            font-size: 36px;
            line-height: 46px;
        }
        h2 {
            font-size: 30px;
            line-height: 40px;
        }
        h3 {
            font-size: 26px;
            line-height: 36px;
        }
        h4 {
            font-size: 22px;
            line-height: 33px;
        }
        h5 {
            font-size: 20px;
            line-height: 30px;
        }
        h6 {
            font-size: 16px;
            line-height: 26px;
        }
    }
}