@media all and (min-width:768px) {
    .catalog-product-view {
        .product.media {
            width: 48.45%;
            margin-bottom: 0;
        }
        .product-info-main {
            width: 47.53%;
        }
    }
}

.catalog-product-view {
    .page-main {
        margin-top: 92px;
    }
}

.product.media {
    position: relative;
    &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        // height: 297px;
        padding-top: 56.25%;
        background: #E9EBF0;
        content: "";
        display: block;
        z-index: -1;
        border-radius: 10px;
    }
}

.fotorama__thumb {
    background-color: #e9ebf0;
}

.fotorama__thumb-border {
    border: 1px solid #20386a;
}

.fotorama__dot {
    background-color: #e9ebf0;
    border-color: #e9ebf0;
}

.fotorama__active .fotorama__dot {
    background-color: #20386a;
    border-color: #20386a;
}

.fotorama__nav--thumbs {
    margin-top: 20px;
}

.product-info-main {
    .page-title-wrapper {
        h1 {
            color: #20386a;
            font-family: $font_khandlight;
            font-size: 40px;
            line-height: 1.2;
            margin: 0 0 10px;
        }
    }
    .product.attribute.sku {
        font-family: $font_khandsemibold;
        font-size: 24px;
        // line-height: 2.4167;
        line-height: 1.2;
        color: #262424;
        margin: 0 0 40px;
        .type {
            &:after {
                display: none;
            }
        }
    }
    .product.attribute.overview {
        margin: 0 0 40px;
        &,
        & p {
            color: #637497;
        }
    }
    .product-info-price {
        display: block;
        border: 0;
        margin: 0;
        .price-box {
            display: block;
            vertical-align: initial;
            width: auto;
            padding: 0;
            margin: 0 0 20px;
            .price-container {
                >span {
                    margin: 0;
                    &.price-excluding-tax {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
    .product-info-stock-sku {
        display: block;
        vertical-align: initial;
        text-align: initial;
        padding: 0;
    }
    .stock {
        background-position: 0;
        padding: 0 0 0 25px;
        color: #262424;
        font-size: 14px;
        background-position: left center;
        background-repeat: no-repeat;
        margin: 0;
        &.available {
            background-image: url('../images/in-stock.svg');
            background-size: 16px 12px;
            font-weight: normal;
            text-transform: none;
            display: flex;
        }
        &.unavailable {
            background-image: url('../images/out-of-stock.svg');
            background-size: 16px 16px;
            font-weight: normal;
            text-transform: none;
        }
    }
    .product-add-form {
        padding-top: 30px;
    }
}

.product-options-bottom,
.product-info-price {
    .price-box {
        .price-container {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            .price {
                color: #262424;
                font-family: $font_khandbold;
                font-weight: normal;
                font-size: 32px;
                line-height: 1;
            }
            .price-including-tax+.price-excluding-tax,
            .weee[data-label] {
                &:before {
                    color: #262424;
                    font-family: $font_khandmedium;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
            .price-including-tax+.price-excluding-tax,
            .weee+.price-excluding-tax,
            .weee {
                .price {
                    color: #262424;
                    font-family: $font_khandmedium;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
        }
    }
}

.product.info.detailed {
    padding-top: 48px;
}

.box-tocart {
    .fieldset {
        display: flex;
        flex-wrap: wrap;
    }
    .qty {
        margin: 0;
        .label {
            display: none;
        }
        .control {
            display: flex;
            flex-wrap: wrap;
        }
        .input-text {
            border: 0;
            background: #E9EBF0;
            color: #262424;
            width: 58px;
            height: 51px;
            text-align: center;
            line-height: 51px;
            font-size: 18px;
            cursor: default;
        }
        .qty-change {
            background: #20386A;
            color: #fff;
            display: block;
            width: 50px;
            height: 51px;
            text-align: center;
            line-height: 51px;
            font-size: 0;
            background-repeat: no-repeat;
            &.minus {
                border-radius: 50% 0 0 50%;
                background-image: url('../images/minus.svg');
                background-size: 16px 16px;
                background-position: 21px center;
            }
            &.plus {
                border-radius: 0 50% 50% 0;
                background-size: 16px 16px;
                background-image: url('../images/plus.svg');
                background-position: 14px center;
            }
        }
    }
    .actions {
        margin: 0 0 0 32px;
        .action.tocart {
            margin: 0;
            display: block;
            font-size: 20px;
            line-height: 1.4;
            font-family: $font_khandregular;
            padding: 0;
            min-height: 51px;
            width: 280px;
        }
    }
}

.product.attribute.description {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
            width: 45.42%;
            margin: 0 0 15px;
        }
    }
}

body {
    .product.data.items {
        margin: 0;
        border: 0;
        >.item {
            &.title {
                margin: 0 20px 0 0;
                >.switch {
                    color: #20386A !important;
                    font-family: $font_khandsemibold;
                    font-size: 24px;
                    line-height: 1.4;
                    padding: 0 !important;
                    border: 0;
                    height: auto;
                }
            }
        }
        .content {
            margin-top: 58px !important;
            padding: 25px 0 0 !important;
            border-color: #E9EBF0 !important;
            border-style: solid;
            border-width: 1px 0 0 0 !important;
            &,
            & p {
                color: #637497;
            }
            .table-wrapper {
                .table:not(.totals):not(.cart):not(.table-comparison) {
                    >tbody {
                        >tr {
                            td[data-th],
                            th[data-th] {
                                &:before {
                                    color: #637497;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:1199px) {
    .box-tocart {
        .actions {
            .action.tocart {
                width: 200px;
            }
        }
    }
}

@media all and (max-width:991px) {
    .catalog-product-view {
        .column.main {
            display: flex;
            flex-direction: column;
        }
        .product.media {
            width: 100%;
            margin-bottom: 50px;
            order: -1;
        }
        .product-info-main {
            width: 100%;
        }
    }
    .product.attribute.description {
        ul {
            li {
                width: 100%;
                margin: 0 0 10px;
            }
        }
    }
}

@media all and (max-width:767px) {
    .catalog-product-view {
        .page-main {
            margin-top: 50px;
        }
    }
    .product-info-main {
        .page-title-wrapper {
            h1 {
                font-size: 36px;
            }
        }
        .product.attribute.sku {
            font-family: khandsemibold;
            font-size: 22px;
        }
        .product.attribute.sku {
            margin: 0 0 25px;
        }
        .product-info-main .product.attribute.overview {
            margin: 0 0 25px;
        }
    }
    .product-info-price,
    .product-options-bottom {
        .price-box {
            .price-container {
                .price {
                    font-size: 29px;
                }
                .price-including-tax+.price-excluding-tax,
                .weee[data-label] {
                    &:before {
                        font-size: 18px;
                    }
                }
                .price-including-tax+.price-excluding-tax,
                .weee+.price-excluding-tax,
                .weee {
                    .price {
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .product.info.detailed {
        padding-top: 30px;
    }
    .box-tocart {
        .fieldset {
            flex-direction: column;
        }
        .actions {
            margin: 15px 0 0;
            .action.tocart {
                width: 100%;
                max-width: 280px;
            }
        }
    }
    body {
        .product.data.items {
            >.item {
                &.title {
                    padding: 15px 0 10px;
                    border-bottom: 1px solid #e9ebf0;
                    // margin: 0 20px 0 0;
                    >.switch {
                        /* color: #20386A !important;
                        font-family: $font_khandsemibold;
                        font-size: 24px;
                        line-height: 1.4;
                        padding: 0 !important;
                        border: 0;
                        height: auto; */
                    }
                }
            }
            .content {
                padding: 10px 0 30px !important;
                margin: 0 !important;
                border: 0 !important;
                /* margin-top: 58px !important;
                border-color: #E9EBF0 !important;
                border-style: solid;
                border-width: 1px 0 0 0 !important; */
                &,
                & p {
                    // color: #637497;
                }
            }
        }
    }
}