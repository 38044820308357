$font_primary: 'open_sansregular';
$font_open_sansbold: 'open_sansbold';
$font_open_sansitalic: 'open_sansitalic';
$font_open_sanslight: 'open_sanslight';
$font_open_sanssemibold: 'open_sanssemibold';
$font_khandbold: 'khandbold';
$font_khandlight: 'khandlight';
$font_khandmedium: 'khandmedium';
$font_khandregular: 'khandregular';
$font_khandsemibold: 'khandsemibold';


