.page-layout-checkout {
    .page-header {
        .header.content {
            align-items: center;
            .logo {
                margin-left: 0;
            }
            .header-contact {
                position: static;
                display: block;
            }
        }
    }
}

.progress-bar {
    background-color: #20386A;
    .opc-progress-bar-item {
        >span {
            font-family: $font_khandsemibold;
        }
    }
}

.cart-container {
    .cart_sku {
        font-family: $font_khandsemibold;
        font-size: 16px;
        line-height: 28px;
        color: #262424;
    }
    .cart-summary #block-shipping {
        display: none;
    }
    .form-cart .actions.main .continue {
        display: block;
    }
    .cart.table-wrapper {
        .qty {
            margin: 0;
            label {
                display: flex;
                // flex-wrap: wrap;
                justify-content: center;
            }
            .input-text {
                border: 0;
                background: #E9EBF0;
                color: #262424;
                width: 48px;
                height: 42px;
                text-align: center;
                line-height: 42px;
                font-size: 18px;
                cursor: default;
                margin: 0;
            }
            .qty-change {
                background: #20386A;
                color: #fff;
                display: block;
                width: 41px;
                height: 42px;
                text-align: center;
                line-height: 42px;
                font-size: 0;
                background-repeat: no-repeat;
                &.minus {
                    border-radius: 50% 0 0 50%;
                    background-image: url('../images/minus.svg');
                    background-position: center center;
                }
                &.plus {
                    border-radius: 0 50% 50% 0;
                    background-size: 16px 16px;
                    background-image: url('../images/plus.svg');
                    background-position: center center;
                }
            }
        }
    }
}

.abs-shopping-cart-items-desktop,
.block-cart-failed {
    max-width: 1010px;
    padding-right: 32px;
    width: 76.82%;
}

.cart-container {
    .form-cart,
    .cart-gift-item {
        max-width: 1010px;
        padding-right: 32px;
        width: 76.82%;
    }
    .cart.actions {
        display: flex;
        justify-content: space-between;
        .action.continue,
        .action.update {
            width: 280px;
            height: 48px;
            border: 3px solid #FFBE5F;
            background-color: #FFF;
            border-radius: 27.5px !important;
            font-family: $font_khandregular;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #262424;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in-out .25s;
            &:hover {
                background-color: #FFBE5F;
            }
        }
    }
}

.checkout-shipping-method,
.checkout-payment-method {
    .actions-toolbar {
        >.primary {
            float: none;
        }
        .action.primary {
            width: 280px;
            height: 48px;
            border: 3px solid #FFBE5F;
            border-radius: 27.5px !important;
            font-family: $font_khandregular;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: #262424;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all ease-in-out .25s;
            &:hover {
                background-color: #FFF;
            }
        }
    }
}

.cart-summary {
    width: 23.18%;
    background-color: #E9EBF0;
    border-radius: 10px;
    padding: 0;
    padding-bottom: 30px;
    >.title {
        border-radius: 10px 10px 0px 0px;
        background-color: #637497;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 35px;
        margin-bottom: 20px;
        font-family: $font_khandmedium;
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        color: #FFFFFF;
    }
    .cart-totals,
    .checkout-methods-items {
        margin-left: 30px;
        margin-right: 30px;
    }
    .cart-totals {
        border-top: 0;
        padding-top: 0;
    }
    .checkout-methods-items {
        .action.primary.checkout {
            padding: 14px 0 14px 0;
        }
    }
}

.opc-wrapper {
    input.input-text,
    select.select {
        border-color: #CED1D7;
        border-radius: 5px;
        height: 48px;
        font-family: $font_open_sansitalic;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        color: #909090;
        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #909090;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: #909090;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: #909090;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: #909090;
        }
    }
}

.opc-block-shipping-information {
    padding-left: 128px;
}

.minicart-items {
    .product-item-details {
        padding-left: 120px;
    }
    .product-item-name {
        font-family: $font_khandbold;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #20386A;
    }
    .product-item-details {
        .details-qty {
            font-size: 12px;
            line-height: 14px;
            color: #515050;
        }
    }
}

.opc-block-summary {
    .product-item {
        .price {
            font-family: $font_open_sanssemibold;
            font-size: 18px;
            line-height: 58px;
            color: #637497;
        }
    }
}

.opc-block-shipping-information {
    .shipping-information-title {
        font-family: $font_khandregular;
        font-weight: 400;
        font-size: 40px;
        line-height: 58px;
        color: #20386A;
    }
}

.payments .fieldset>.legend+br {
    display: none;
}

@media (max-width: 1199px) {
    .cart-summary {
        width: 100%;
        position: static;
    }
    .abs-shopping-cart-items-desktop,
    .block-cart-failed {
        max-width: 100%;
        padding-right: 0px;
        width: 100%;
    }
    .cart-container {
        display: flex;
        flex-wrap: wrap;
        .form-cart,
        .cart-gift-item {
            max-width: 100%;
            padding-right: 0px;
            width: 100%;
        }
    }
    .opc-block-summary,
    .opc-block-shipping-information {
        padding-left: 30px;
    }
}

@media (max-width: 991px) {
    .checkout-cart-index {
        .page-title-wrapper {
            margin-top: 20px;
            margin-bottom: 10px;
        }
        .page-header .header.content .nav-toggle {
            display: none;
        }
    }
    .cart-summary {
        order: 2;
        >.title {
            display: flex;
        }
        .cart-totals,
        .checkout-methods-items {
            margin-left: 15px;
            margin-right: 15px;
        }
    }
    .cart-container {
        .cart.table-wrapper {
            .qty label {
                justify-content: flex-start;
            }
        }
    }
    .minicart-items {
        .product {
            >.product-image-container {
                max-width: 75px;
                height: auto !important;
            }
        }
        .product-item-details {
            padding-left: 90px;
        }
    }
    .opc-block-summary {
        padding: 20px 15px;
        padding-left: 15px;
    }
    .opc-wrapper {
        padding-top: 20px;
    }
}

@media (max-width: 767px) {
    .checkout-cart-index {
        .page-header .header.content .header-contact {
            display: block;
        }
    }
    .cart-container {
        .form-cart {
            .actions.main {
                flex-wrap: wrap;
            }
        }
        .cart.actions {
            .action.continue,
            .action.update {
                margin-bottom: 15px;
                width: 100%;
            }
        }
    }
    .custom-slide {
        .modal-inner-wrap {
            background-color: #e9ebf0;
        }
    }
    .opc-estimated-wrapper {
        background-color: #e9ebf0;
        margin: 0;
        margin-top: 15px;
        .minicart-wrapper {
            .action.showcart {
                &:before {
                    content: '';
                    background-image: url('../images/cart.png');
                    width: 32px;
                    height: 28px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
                .counter.qty {
                    background-color: #ffbe5f;
                    width: 20px;
                    height: 20px;
                    border-radius: 25px;
                    text-align: center;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    line-height: 16px;
                    .counter-number {
                        text-shadow: none;
                        font-family: open_sansbold;
                        font-size: 12px;
                        line-height: 12px;
                        color: #262424;
                    }
                }
            }
        }
    }
    .opc-wrapper {
        .form-shipping-address {
            margin-top: 0;
        }
        .methods-shipping {
            border-bottom: 0;
        }
        .form-login,
        .form-shipping-address,
        .methods-shipping {
            background: none;
            margin: 0;
            padding: 0;
        }
        .step-title {
            font-size: 28px;
        }
    }
    .checkout-container {
        margin-bottom: 0;
    }
}

@media (max-width: 480px) {
    .cart-container {
        .cart_sku {
            font-size: 14px;
            line-height: 18px;
        }
        .cart.table-wrapper {
            .qty {
                padding-left: 0;
                padding-right: 0;
                .input-text {
                    width: 35px;
                }
                .qty-change {
                    background-size: 12px auto;
                }
            }
        }
    }
    .cart.table-wrapper {
        .product-item-name {
            >a {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
    .checkout-payment-method,
    .checkout-shipping-method {
        .actions-toolbar {
            .action.primary {
                width: 100%;
            }
        }
    }
    .opc-wrapper {
        input.input-text,
        select.select {
            height: 42px;
        }
    }
    .opc-estimated-wrapper {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}