.home-banner-main {
    .banner-main {
        height: 641px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
        .banner-container {
            height: 100%;
        }
        .banner-wrapper {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            .banner-top-data {
                background: rgba(233, 235, 240, 0.22);
                border-radius: 0px 0px 100px 100px;
                position: absolute;
                left: 0;
                top: 0px;
                width: 100%;
                padding: 15px 40px 10px;
                .banner-top-title {
                    font-family: $font_khandsemibold;
                    font-size: 20px;
                    line-height: 20px;
                    text-align: center;
                    color: #FFBE5F;
                }
                .banner-top-text {
                    font-family: $font_primary;
                    font-size: 14px;
                    line-height: 26px;
                    text-align: center;
                    color: #FFFFFF;
                }
            }
            .block-search {
                float: none;
                padding: 0;
                width: 100%;
            }
            .banner-search {
                display: flex;
                flex-wrap: wrap;
                .banner-search-title {
                    width: 100%;
                    h1 {
                        color: #fff;
                        font-size: 72px;
                        line-height: 58px;
                        margin-bottom: 35px;
                        font-family: $font_khandsemibold;
                    }
                }
                .search-part {
                    max-width: 604px;
                    width: 100%;
                    .banner-search-form {
                        .block-search {
                            position: relative;
                            .banner-label {
                                color: #FFBE5F;
                                font-size: 32px;
                                line-height: 58px;
                                font-family: $font_khandmedium;
                            }
                            .control {
                                margin: 13px 0px 25px;
                                #search {
                                    border-radius: 27.5px;
                                    font-size: 16px;
                                    line-height: 26px;
                                    padding: 14px 27px;
                                    height: 55px;
                                    background-color: #fff;
                                    border: none;
                                    font-family: $font_primary;
                                }
                            }
                            .actions {
                                position: absolute;
                                right: 18px;
                                bottom: 43px;
                                .banner-label {
                                    display: none;
                                }
                                .search {
                                    transition: all .5s ease;
                                    &:before {
                                        color: #84B0F1;
                                        font-size: 22px;
                                    }
                                    &:hover {
                                        &:before {
                                            color: #262424;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .specific-part {
                    padding-left: 31px;
                    padding-top: 27px;
                    .banner-label {
                        color: #FFBE5F;
                        font-size: 24px;
                        line-height: 24px;
                        font-family: $font_khandmedium;
                    }
                    .specific-link {
                        margin-top: 20px;
                        a {
                            background-color: #ffbe5f;
                            border-radius: 28px;
                            color: #262424;
                            font-size: 20px;
                            line-height: 24px;
                            display: block;
                            padding: 14px 10px;
                            width: 100%;
                            text-align: center;
                            text-decoration: none;
                            margin-top: 15px;
                            transition: all .5s ease;
                            border: 2px solid #ffbe5f;
                            font-family: $font_khandregular;
                            &:hover {
                                //background-color: #20386a;
                                //color: #fff;
                                background-color: #262424;
                                color: #ffbe5f;
                            }
                        }
                    }
                }
                .banner-search-text {
                    color: #fff;
                    font-size: 16px;
                    line-height: 26px;
                    max-width: 520px;
                    font-family: $font_primary;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .home-banner-main {
        .banner-main {
            .banner-wrapper {
                .banner-search {
                    .search-part {
                        max-width: 560px;
                    }
                    .specific-part {
                        padding-left: 24px;
                    }
                    .banner-search-text {
                        max-width: 400px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .home-banner-main {
        .banner-main {
            padding-top: 0px;
            .banner-wrapper {
                .banner-top-data {
                    padding: 15px 20px 10px;
                    border-radius: 0px;
                    margin: 0px -20px;
                    width: calc(100% + 40px);
                    top: 0;
                }
                .banner-search {
                    max-width: 654px;
                    margin: 0px auto;
                    .banner-search-title {
                        h1 {
                            font-size: 64px;
                        }
                    }
                    .search-part {
                        max-width: 352px;
                    }
                    .banner-search-text {
                        max-width: 300px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .home-banner-main {
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, #000000 100%);
            mix-blend-mode: multiply;
            opacity: 0.35;
            z-index: 0;
        }
        .banner-main {
            height: 875px;
            .banner-container {
                padding-right: 8px;
                padding-left: 8px;
            }
            .banner-wrapper {
                z-index: 1;
                .banner-top-data {
                    margin: 0 -8px;
                    width: calc(100% + 16px);
                    .banner-top-text {
                        font-size: 12px;
                        line-height: 20px;
                    }
                }
                .banner-search {
                    text-align: center;
                    height: 100%;
                    padding-top: 110px;
                    flex-direction: column;
                    .banner-search-title {
                        margin-top: 32px;
                        h1 {
                            font-size: 48px;
                            line-height: 48px;
                            margin-bottom: 17px;
                        }
                    }
                    .search-part {
                        max-width: 100%;
                        .banner-search-form {
                            .block-search {
                                margin-top: 0;
                                .banner-label {
                                    font-size: 24px;
                                    line-height: 58px;
                                }
                                .control {
                                    border: none;
                                    margin-bottom: 30px;
                                    margin-top: 0;
                                    #search {
                                        position: inherit;
                                        margin: 0px;
                                    }
                                }
                                .actions {
                                    bottom: 12px;
                                    right: 35px;
                                    .search {
                                        display: block;
                                        background-color: transparent;
                                        padding: 0px;
                                        &:before {
                                            color: #84b0f1;
                                            font-size: 22px;
                                            line-height: 32px;
                                            content: '\e615';
                                            font-family: 'luma-icons';
                                            margin: 0;
                                            vertical-align: top;
                                            display: inline-block;
                                            font-weight: normal;
                                            overflow: hidden;
                                            text-align: center;
                                        }
                                        span {
                                            display: none;
                                        }
                                        &:hover {
                                            &:before {
                                                color: #262424;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .specific-part {
                        width: 100%;
                        padding-left: 0;
                        padding-top: 0px;
                        .specific-link {
                            margin: 20px auto 0px;
                            max-width: 263px;
                        }
                    }
                    .banner-search-text {
                        max-width: 100%;
                        margin: auto 0px 26px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .home-banner-main {
        .banner-main {
            .banner-wrapper {
                .banner-search {
                    .search-part {
                        .banner-search-form {
                            .block-search {
                                .control {
                                    #search {
                                        padding: 14px 27px 14px 14px;
                                    }
                                }
                                .actions {
                                    right: 28px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}