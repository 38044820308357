body {
    .columns {
        .btn {
            background-color: #ffbe5f;
            border-radius: 28px;
            color: #262424;
            font-size: 20px;
            line-height: 24px;
            display: inline-block;
            padding: 14px 10px;
            text-align: center;
            text-decoration: none;
            margin-top: 15px;
            min-width: 150px;
            transition: all .5s ease;
            border: 2px solid #ffbe5f;
            font-family: $font_khandregular;
            &:hover {
                background-color: #262424;
                color: #ffbe5f;
            }
        }
        blockquote {
            border-left: 4px solid #20386a;
            margin: 0 0 20px 20px;
            padding-left: 20px;
        }
    }
}

@media only screen and (max-width: 767px) {
    body {
        .columns {
            .btn {
                padding: 6px 10px;
                min-width: 130px;
            }
            ul,
            ol {
                padding-left: 20px;
            }
        }
    }
}