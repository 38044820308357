.home_only_image_slider {
    position: relative;
    .item {
        .image-section {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            img {
                width: 100%;
                min-height: 512px;
            }
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        z-index: 9;
        &.slick-prev {
            left: 32px;
        }
        &.slick-next {
            right: 32px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .home_only_image_slider {
        .item {
            .image-section {
                img {
                    min-height: 308px;
                }
            }
        }
        .slick-arrow {
            &.slick-prev {
                left: 8px;
            }
            &.slick-next {
                right: 8px;
            }
        }
    }
}