.is-blog , .cms-page-view{
    .page-main{
        >.page-title-wrapper {
            .page-title {
                font-family: $font_khandregular;
                font-weight: normal;
                font-size: 40px;
                line-height: 58px;
                color: #20386A;
                margin: 32px 0 30px;
                text-align: center;
                display: block;
            }
        }
    }
    .top-cat-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 0 25px;
        .top-cat-item {
            padding: 0 53px;
            a{
                font-family: $font_khandsemibold;
                font-size: 22px;
                line-height: 31px;
                color: #637497;
                border-bottom: 4px solid transparent;
                transition: all ease .4s;
                display: inline-block;
                &:hover{
                    border-bottom: 4px solid #FFBE5F;
                    color: #20386A;
                }
            }
        }
    }
    .post-list-wrapper{
        .pager {
            border-bottom: 1px solid #E9EBF0;
            margin: 0 0 29px;
            .toolbar-amount {
                display: block;
                float: none;
                position: relative;
                font-family: $font_primary;
                font-size: 16px;
                line-height: 50px;
                text-transform: uppercase;
                color: #515050;
                padding: 0;
            }            
            .pages {
                display: none;
            }
        }
        .post-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -16px;
            padding: 0;
            li {
                display: flex;
                flex-direction: column;
                width: 33.33%;
                margin: 0 0 64px;
                padding: 0 16px;
                .post-list-item {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                }
                .post-entry {
                    margin: 0 0 16px;
                    overflow: visible;
                    .post-image {
                        margin: 0;
                        a{
                            background-position: center center;
                            background-repeat: no-repeat;
                            background-size: cover;
                            display: block;
                        }
                    }
                }
                .post-meta{
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    h2{
                        margin: 0 0 15px;
                        line-height: inherit;
                        a{
                            font-family: $font_khandregular;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 37px;
                            color: #20386A;
                            &:hover{
                                color: #637497;
                            }
                        }
                    }
                    .post-excerpt {
                        font-size: 16px;
                        line-height: 26px;
                        color: #262424;
                        margin: 0 0 18px;
                        font-family: $font_primary;
                    }
                    .post-data {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin-top: auto;
                        .post-user-data {
                            font-family: $font_khandmedium;
                            font-size: 18px;
                            line-height: 28px;
                            color: #637497;
                            display: flex;
                            align-items: center;
                            .post-meta-item {
                                margin-right: 10px;
                                &.post-date{
                                    margin-left: 10px;
                                    margin-right: 0;
                                }
                            }
                            a{
                                font-family: $font_khandmedium;
                                font-size: 18px;
                                line-height: 28px;
                                color: #637497;
                                &:hover{
                                    color: #20386A;
                                }
                            }
                        }
                        .read-more-link{
                            a{
                                font-family: $font_khandmedium;
                                font-size: 18px;
                                line-height: 28px;
                                text-decoration-line: underline;
                                color: #637497;
                                &:hover{
                                    color: #20386A;
                                }
                            }
                        }
                    }
                }
            }
        }
        .bottom-toolbar{
            .pager {
                border-bottom: 0;
                margin: 0 0 65px;
                .toolbar-amount {
                    display: none;
                }            
                .pages {
                    display: block;
                    text-align: center;
                    margin: 30px 0 0 !important;
                }
            }
        }
    }
}

@media screen and (max-width:1199px){
    .is-blog , .cms-page-view {
        .post-list-wrapper {
            .post-list {
                li {
                    .post-meta {
                        .post-data{
                            .post-user-data {
                                font-size: 14px;
                                a{
                                    font-size: 14px;
                                }
                            }
                            .read-more-link {
                                a{
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:991px){
    .is-blog , .cms-page-view {
        .top-cat-list {
            .top-cat-item {
                padding: 0 30px;
            }
        }
        .post-list-wrapper {
            .post-list{
                margin: 0 -10px;
                li {
                    padding: 0 10px;
                    .post-meta {
                        h2 {
                            a {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                        .post-excerpt {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }                
                }
            }
        }
    }
}

@media screen and (max-width:767px){
    .is-blog , .cms-page-view {
        .top-cat-list {
            .top-cat-item {
                padding: 0 20px;
            }
        }
        .post-list-wrapper {
            .bottom-toolbar {
                .pager {
                    margin: 0 0 20px;
                }
            }
            .pager{
                .pages {
                    .pages-items {
                        display: inline-block;
                        padding: 0px 40px 0;
                    }
                }
            }
            .post-list{
                li {     
                    width: 50%;     
                    margin: 0 0 34px;     
                }
            }
        }
    }
}

@media screen and (max-width:575px){
    .is-blog , .cms-page-view {
        .top-cat-list {
            .top-cat-item {
                padding: 0 20px;
                width: 50%;
                text-align: center;
            }
        }
        .post-list-wrapper {
            .pager{
                .toolbar-amount {
                    font-size: 14px;
                }
            }
            .bottom-toolbar {
                .pager {
                    .pages {
                        display: block !important;
                    }
                }
            }
            .post-list{
                li {     
                    width: 100%;   
                    margin: 0 0 25px;
                    .post-entry {
                        .post-image {
                            a {
                                width: 100%;
                                img{
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}