.image-with-text-half-width {
    .image-with-text-section {
        .section-inner {
            position: relative;
            .image-section {
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 50%;
                img {
                    width: 100%;
                }
            }
            .content-section {
                .content-section-inner {
                    width: 50%;
                    margin-left: auto;
                    color: #fff;
                    min-height: 434px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 30px;
                    .content {
                        max-width: 458px;
                        p {
                            color: #fff;
                            font-size: 24px;
                            line-height: 34px;
                            font-family: $font_khandregular;
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
            &.image-left {
                background-color: #637497;
            }
            &.image-right {
                background-color: #20386A;
                .image-section {
                    right: 0;
                    left: inherit;
                }
                .content-section {
                    .content-section-inner {
                        margin-left: initial;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .image-with-text-half-width {
        .image-with-text-section {
            .section-inner {
                .image-section {
                    position: inherit;
                    width: 100%;
                    img {
                        height: 380px;
                    }
                }
                .content-section {
                    .content-section-inner {
                        width: 100%;
                        min-height: auto;
                        padding: 64px 0px;
                        .content {
                            max-width: 540px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .image-with-text-half-width {
        .image-with-text-section {
            .section-inner {
                .image-section {
                    img {
                        height: 312px;
                    }
                }
                .content-section {
                    .content-section-inner {
                        text-align: center;
                        .content {
                            padding: 0px 1px;
                        }
                    }
                }
            }
        }
    }
}