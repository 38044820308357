.brands-list-section {
	.brands-list-title {
		color: #262424;
		font-size: 20px;
		line-height: 58px;
		font-family: $font_khandsemibold;
		padding: 0;
	}
	.brands-list-page {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-left: -3%;
		margin-right: -3%;
		margin-top: -3%;
		.brandItem {
			width: 25%;
			padding: 3%;
			.brandItemLink {
				height: 110px;
				display: block;
				padding: 20px;
				border: 1px solid #e9ebf0;
				.am-image-wrapper {
					display: flex;
					align-items: center;
					height: 100%;
					width: 100%;
					justify-content: center;
				} 
			}
		}
	} 
}

.cms-brands {
	.columns {
		.amasty-catalog-topnav {
			display: none;
		}
	}
}
@media only screen and (max-width: 1199px) {
	.brands-list-section {
		.brands-list-page {
			margin-left: -12px;
			margin-right: -12px;
			margin-top: -12px;
			.brandItem {
				width: 33.33%;
				padding: 12px;
			}
		}
	}
}
@media only screen and (max-width: 767px) {
	.cms-brands {
		.columns {
			.sidebar-main {
				order: 0;
			}
		}
	}
	.brands-list-section {
		margin-bottom: 50px;
	} 
}
@media only screen and (max-width: 640px) {
	.brands-list-section {
		.brands-list-page {
			.brandItem {
				width: 50%;
			}
		}
	}
}
@media only screen and (max-width: 400px) {
	.brands-list-section {
		.brands-list-page {
			.brandItem {
				width: 100%;
			}
		}
	}
}