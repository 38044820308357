.cms-trade-account-form {
    .page-title {
        text-align: left !important;
    }
}

.customer-account-create,
.cms-trade-account-form {
    .action {
        &.primary {
            width: 280px;
            height: 51px;
        }
    }
    .page-title {
        color: #20386A;
        font-size: 40px;
        line-height: 58px;
        font-family: $font_khandregular;
        margin-top: 32px;
        margin-bottom: 16px;
    }
    .form {
        &.create {
            &.account {
                width: 100%;
                max-width: 970px;
            }
        }
    }
    .fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0px;
        legend {
            color: #20386A;
            font-size: 24px;
            line-height: 58px;
            font-family: $font_khandsemibold;
            border: none;
            margin-bottom: 6px;
            padding: 0;
            width: 100%;
        }
        .field {
            width: 42.79%;
            &.street {
                .nested {
                    margin-top: 20px;
                    .field {
                        width: 100%;
                        margin-bottom: 20px;
                        .label {
                            position: inherit;
                            margin: 0px;
                            overflow: visible;
                            clip: inherit;
                            height: 100%;
                        }
                    }
                    .field:last-child {
                        display: none;
                    }
                }
            }
            label {
                color: #20386A;
                font-size: 14px;
                line-height: 26px;
                font-weight: 600;
                width: 100% !important;
                height: auto !important;
                display: inline-block !important;
                margin-bottom: 8px !important;
            }
            input {
                border: 1px solid #CED1D7;
                box-sizing: border-box;
                border-radius: 5px;
                height: 48px;
            }
            select {
                border: 1px solid #CED1D7;
                box-sizing: border-box;
                border-radius: 5px;
                height: 48px;
            }
        }
        &:after {
            content: none !important;
        }
    }
    .left-right-account {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;
        .left-acount {
            width: 42.79%;
            .field {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        .right-acount {
            width: 42.79%;
            .field {
                width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .customer-account-create {
        .fieldset {
            flex-direction: column;
            .field {
                width: 100%;
            }
            br {
                display: none;
            }
        }
        .left-right-account {
            flex-direction: column;
            .left-acount {
                width: 100%;
            }
            .right-acount {
                width: 100%;
            }
        }
    }
}