body {
    .message.notice {
        margin: 0 0 10px;
        padding: 12px 25px 12px 50px;
        display: block;
        font-size: 1.3rem;
        background: #20386a;
        color: #fff;
        a {
            color: #fff;
            &:hover {
                color: #ffbe5f;
            }
        }
        >*:first-child:before {
            color: #fff;
            top: 30px;
        }  
    }
}

.products-grid {
    .product-item {
        .price-container {
            .price-excluding-tax {
                display: none;
            }
        }
    }
}

.page-layout-2columns-left {
    .category-top {
        width: 75.7%;
        float: right;
        padding-left: 16px;
        .page-title {
            font-size: 40px;
            line-height: 58px;
            color: #20386A;
            font-family: $font_khandlight;
            border-bottom: 1px solid #E9EBF0;
            margin-top: 23px;
            margin-bottom: 0;
            margin-top: 23px;
            padding-bottom: 21px;
        }
    }
    .catalog-topnav {
        &.amasty-catalog-topnav {
            @media (max-width:767px) {
                display: none;
            }
            .filter-options {
                .filter-options-content {
                    .items {
                        width: 100%;
                        max-width: 626px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 0;
                        .item {
                            width: 50%;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;   
                            align-items : flex-start;
                            justify-content: flex-start;
                            margin: 15px 0px 0px 0px;
                            padding-left: 0;
                            @media (max-width:991px) {
                                width: 100%;
                            }
                            .am-input {
                                display: none;
                            }
                            a {
                                background-color: #E9EBF0;
                                border-radius: 56px;
                                padding: 20px 40px;
                                display: block;
                                min-width: 282px;
                                &:hover {
                                    font-weight: bold;
                                }
                                @media (max-width:1199px) {
                                    padding: 15px 30px;
                                    min-width: 250px;
                                }
                                @media (max-width:991px) {
                                    min-width: 282px;
                                }
                            }
                        }
                        &.items-children {
                            width: 100%;
                            .item {
                                width: 100%;
                                margin-bottom: 0;
                                padding-left: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .toolbar-products {
        margin: 0px;
        .toolbar-amount {
            color: #262424;
            font-size: 20px;
            line-height: 58px;
            font-family: $font_khandsemibold;
            padding: 12px 0 7px 0;
        }
    }
    .products-grid {
        margin: 0;
        .product-items {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;
        }
        .product-item {
            position: relative;
            text-align: center;
            margin-bottom: 60px;
            &:after {
                background: #e9ebf0;
                border-radius: 10px;
                width: 100%;
                height: 185px;
                position: absolute;
                top: 0;
                left: 0;
                content: "";
            }
            .product-item-info {
                display: flex;
                flex-direction: column;
                height: 100%;
                a.product-item-photo {
                    display: block;
                    position: relative;
                    .product-image-photo {
                        margin-top: 0px;
                        margin-bottom: 0px;
                    }
                }
                .product-item-details {
                    display: flex;
                    flex-direction: column;
                    .product-item-name {
                        margin: 5px 0px;
                        a {
                            color: #20386A;
                            font-size: 28px;
                            line-height: 34px;
                            font-family: $font_khandregular;
                            text-decoration: none;
                            &:hover {
                                color: #FFBE5F;
                            }
                        }
                    }
                    .price-box {
                        margin: 0px 0 18px;
                        .price {
                            color: #262424;
                            font-size: 32px;
                            line-height: 58px;
                            font-family: $font_khandbold;
                        }
                        .price-excluding-tax {
                            display: none;
                        }
                    }
                    .product-item-inner {
                        margin-top: auto;
                        .product-item-actions {
                            .actions-primary {
                                display: block;
                                .tocart {
                                    &.primary {
                                        border: 3px solid #ffbe5f;
                                        border-radius: 27.5px;
                                        width: 100%;
                                        color: #262424;
                                        background-color: transparent;
                                        font-size: 20px;
                                        line-height: 25px;
                                        max-width: 280px;
                                        padding-top: 12px;
                                        padding-bottom: 12px;
                                        transition: all .5s ease;
                                        &:hover {
                                            background-color: #262424;
                                            color: #ffbe5f;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .top-load-back {
        display: none;
        span {
            text-decoration-line: underline;
            color: #637497;
            font-size: 20px;
            line-height: 24px;
            font-family: $font_khandsemibold;
        }
    }
}

.pages {
    margin-top: 20px;
    margin-bottom: 40px !important;
    .pages-items {
        .item {
            width: 34px;
            height: 34px;
            &.current {
                .page {
                    background-color: #20386A;
                    width: 34px;
                    height: 34px;
                    color: #fff;
                    font-size: 18px;
                    line-height: 28px;
                    font-family: $font_khandbold;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            a {
                padding: 0px;
                color: #545454;
                font-size: 18px;
                line-height: 34px;
                font-family: $font_khandlight;
                display: block;
                border-radius: 100%;
                &:hover {
                    background-color: #20386A;
                    color: #fff;
                    font-family: $font_khandbold;
                }
            }
            .action {
                background: none;
                box-shadow: none;
                border: none;
                margin: 0 !important;
                &:before {
                    color: #20386A;
                }
                &:hover {
                    background-color: #20386A;
                    &:before {
                        color: #fff;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .amasty-catalog-topnav {
        margin: 0;
        border-bottom: 1px solid #E9EBF0;
        padding: 0 0 28px;
        .gray-back {
            display: none;
        }
        .filter-options {
            margin: 0;
            padding: 0;
            border: 0;
            .filter-options-content {
                min-width: auto;
                position: relative;
                margin: 0;
                border: 0;
                padding: 0;
                ol.items {
                    display: flex;
                    align-items: center;
                    .item {
                        margin: 0;
                        margin-right: 32px;
                        input {
                            display: none;
                        }
                        a {
                            background: #20386A;
                            min-width: 282px;
                            padding: 17px 40px;
                            text-align: left;
                            font-size: 24px;
                            line-height: 30px;
                            color: #fff;
                            font-family: 'khandregular';
                            margin: 0;
                            display: inline-block;
                            border-radius: 60px;
                            transition: all ease .4s;
                            &:hover {
                                background: #e9ebf0;
                                color: #20386A;
                                ;
                            }
                        }
                    }
                }
            }
        }
        .filter-options-title {
            padding: 0;
            border: 0;
            font-size: 20px;
            line-height: 58px;
            color: #262424;
            font-family: 'khandsemibold';
            text-transform: inherit;
            &::after {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .page-layout-2columns-left {
        .category-top {
            width: 69.7%;
        }
        .column {
            &.main {
                width: 69.7%;
            }
        }
        .products-grid {
            .product-item {
                &:after {
                    height: 105px;
                }
                .product-item-info {
                    .product-item-details {
                        .product-item-name {
                            margin: 20px 0 10px;
                            a {
                                font-size: 28px;
                                line-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
    .amasty-catalog-topnav {
        .filter-options {
            .filter-options-content {
                ol.items {
                    .item {
                        a {
                            min-width: 200px;
                            text-align: center;
                            padding: 19px 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .page-layout-2columns-left {
        .category-top {
            width: 66.7%;
        }
        .column {
            &.main {
                width: 66.7%;
            }
        }
        .products-grid {
            .product-item {
                width: calc(50% - 16px);
                margin-left: 32px !important;
                &:nth-child(2n + 1) {
                    margin-left: 0 !important;
                }
                &:after {
                    content: none;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .page-layout-2columns-left {
        .category-top {
            padding-left: 0px;
            width: 100%;
            .page-title {
                font-size: 36px;
                line-height: 36px;
                margin-top: 0px;
                border-bottom: 1px solid #E9EBF0;
                text-align: center;
                font-family: $font_khandlight;
                margin-bottom: 28px;
                padding-bottom: 27px;
            }
        }
        .column {
            &.main {
                width: 100%;
                padding: 0px;
            }
        }
        .toolbar-products {
            .toolbar-amount {
                padding-top: 15px;
                padding-bottom: 5px;
                text-align: center;
            }
        }
    }
    .filter-title {
        strong {
            position: relative;
            top: inherit;
            font-family: $font_khandmedium;
            box-shadow: none;
            border: none;
            padding-left: 27px;
            font-size: 24px;
            line-height: 37px;
            background-color: #637497;
            color: #fff;
            width: 100%;
            display: block;
            text-align: left;
            border-radius: 10px;
            &:after {
                content: "";
                background-image: url('../images/bottom.svg');
                background-repeat: no-repeat;
                position: absolute;
                top: 50%;
                right: 20px;
                width: 17px;
                height: 11px;
                transform: translateY(-50%);
            }
        }
    }
    .catalog-topnav {
        display: block;
        margin: 0;
        border-bottom: 1px solid #E9EBF0;
        padding: 0 0 28px;
        .gray-back {
            display: none;
        }
        .block-title.filter-title {
            display: none;
        }
        .filter-options-title {
            line-height: normal !important;
            margin: 22px 0 39px;
            text-align: center;
        }
        .filter-options {
            margin: 0;
            padding: 0;
            border: 0;
            display: block;
            .filter-options-content {
                min-width: auto;
                position: relative;
                margin: 0;
                border: 0;
                padding: 0;
                ol.items {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0;
                    .item {
                        margin: 0;
                        margin-right: 0;
                        width: 100%;
                        input {
                            display: none;
                        }
                        a {
                            background: #20386A;
                            min-width: 100%;
                            padding: 17px 40px;
                            text-align: left;
                            font-size: 24px;
                            line-height: 30px;
                            color: #fff;
                            font-family: 'khandregular';
                            margin: 0 0 8px;
                            display: inline-block;
                            border-radius: 60px;
                            transition: all ease .4s;
                            &:hover {
                                background: #e9ebf0;
                                color: #20386A;
                                ;
                            }
                        }
                    }
                }
            }
        }
        .filter-options-title {
            padding: 0;
            border: 0;
            font-size: 20px;
            line-height: 58px;
            color: #262424;
            font-family: 'khandsemibold';
            text-transform: inherit;
            &::after {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .page-layout-2columns-left {
        .products-grid {
            .product-items {
                margin-bottom: 0px;
            }
            .product-item {
                width: 100%;
                margin-left: 0px !important;
                padding-bottom: 31px;
                margin-bottom: 32px;
                border-bottom: 1px solid #E9EBF0;
                .product-item-info {
                    .product-item-details {
                        .product-item-name {
                            margin-bottom: 0;
                            a {
                                font-size: 24px;
                                line-height: 34px;
                            }
                        }
                        .price-box {
                            margin-bottom: 0px;
                            .price {
                                font-size: 24px;
                                line-height: 50px;
                            }
                        }
                    }
                }
                &:nth-of-type(1n+5) {
                    display: none;
                }
            }
        }
        .top-load-back {
            display: flex;
            justify-content: space-between;
            margin-bottom: 48px;
        }
        .toolbar-products {
            .toolbar-amount {
                text-align: center;
            }
        }
    }
    .pages {
        display: none !important;
    }
}