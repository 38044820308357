.page-header {
    .minicart-wrapper {
        .action.showcart {
            .counter.qty.empty {
                display: block;
            }
        }
    }
    .panel.wrapper {
        display: none;
    }
    .header.content {
        display: flex;
        flex-wrap: wrap;
        padding: 21px 30px;
        .block-search {
            float: none;
            padding-left: 0;
            width: 310px;
            order: 1;
            display: flex;
            align-items: center;
            .block-content {
                width: 100%;
            }
            form {
                width: 100%;
            }
            input {
                border-bottom: 1px solid #E9EBF0;
                border-width: 0 0 1px;
                padding: 6px 30px 6px 0;
                font-family: $font_primary;
                font-size: 14px;
                line-height: 26px;
                color: #515050;
                height: 40px;
                &::placeholder {
                    color: #515050;
                    opacity: 1;
                }
                &:not([disabled]):focus {
                    box-shadow: none;
                }
            }
            .action.search {
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                &[disabled] {
                    opacity: 1;
                }
                &:before {
                    content: "";
                    background: url(../images/search-icon.png);
                    width: 22px;
                    height: 22px;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
        .logo {
            margin: 0 auto;
            max-width: 100%;
            order: 2;
            float: none;
        }
        .header-custom-links {
            order: 3;
            margin: 8px 0 0;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;
                li {
                    margin: 0;
                    padding: 0;
                    &.seprator {
                        font-size: 12px;
                        line-height: 26px;
                        color: #515050;
                        span {
                            vertical-align: super;
                        }
                    }
                    a {
                        display: flex;
                        align-items: center;
                        font-family: $font_primary;
                        font-size: 12px;
                        line-height: 26px;
                        color: #515050;
                        span {
                            margin-left: 12px;
                        }
                        &:hover {
                            color: #84B0F1;
                        }
                    }
                }
            }
        }
        #switcher-currency {
            order: 4;
            float: none;
            margin: 10px 0 0 26px;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;
                li {
                    margin: 0;
                    padding: 0 2px;
                    a {
                        font-family: Arial;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 30px;
                        text-align: center;
                        color: #464646;
                        width: 26px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid transparent;
                        border-radius: 100%;
                        &:hover {
                            border: 2px solid #FFBE5F;
                        }
                    }
                    &.active {
                        a {
                            border: 2px solid #FFBE5F;
                        }
                    }
                }
            }
        }
        .minicart-wrapper {
            margin: 10px 0 0 18px;
            float: none;
            height: 100%;
            order: 5;
            .block-minicart {
                list-style: none none;
                background: #fff;
                border: 2px solid #84B0F1;
                margin-top: 12px;
                right: 0;
                &:after {
                    border-color: transparent transparent #84B0F1 transparent;
                }
                &:before {
                    border-color: transparent transparent #84B0F1 transparent;
                }
                .action.close {
                    height: 25px;
                    width: 25px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 5px;
                    right: 5px;
                    &:before {
                        color: #84B0F1;
                        transition: all ease .4s;
                    }
                    &:hover {
                        &:before {
                            color: #464646;
                        }
                    }
                }
                .subtitle.empty {
                    padding: 30px 0;
                    font-family: $font_khandregular;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #262424;
                }
                .product {
                    .actions {
                        margin: -28px 0 0;
                    }
                }
                a.action.viewcart {
                    color: #20386a;
                    &:hover {
                        color: #ffbe5f;
                        text-decoration: none;
                    }
                }
                .minicart-items {
                    .product-item-name {
                        font-weight: normal;
                        margin: 0;
                        a {
                            font-family: $font_khandregular;
                            font-weight: normal;
                            font-size: 16px;
                            line-height: 26px;
                            color: #20386a;
                            &:hover {
                                color: #ffbe5f;
                                text-decoration: none;
                            }
                        }
                    }
                    .product-item-details {
                        .price-including-tax,
                        .price-excluding-tax {
                            margin: 0;
                        }
                    }
                    .action {
                        &.edit,
                        &.delete {
                            &:before,
                            &:before {
                                color: #20386a;
                            }
                            &.edit,
                            &.delete {
                                &:hover {
                                    &:before,
                                    &:before {
                                        color: #ffbe5f;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .action.showcart {
                &:before {
                    content: "";
                    background: url(../images/cart.png);
                    width: 32px;
                    height: 28px;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                .counter.qty {
                    background: #FFBE5F;
                    color: #fff;
                    height: 18px;
                    line-height: 18px;
                    border-radius: 100%;
                    display: inline-block;
                    margin: 0;
                    min-width: 18px;
                    overflow: hidden;
                    padding: 0 3px;
                    text-align: center;
                    white-space: normal;
                    width: 18px;
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    .counter-number {
                        text-shadow: none;
                        font-family: $font_open_sansbold;
                        font-size: 12px;
                        line-height: 12px;
                        color: #262424;
                    }
                }
            }
        }
        .header-contact {
            order: 6;
            width: auto;
            text-align: right;
            position: absolute;
            bottom: 14px;
            right: 30px;
            .header-contact-detail {
                display: inline-flex;
                width: 100%;
            }
            .header-contact-no {
                a {
                    font-family: $font_khandsemibold;
                    font-size: 20px;
                    line-height: 26px;
                    text-align: center;
                    color: #20386A;
                    &:hover {
                        color: #84B0F1;
                    }
                }
            }
            .header-contact-hours {
                font-family: $font_primary;
                font-size: 14px;
                line-height: 26px;
                color: #637497;
                margin-left: 10px;
            }
        }
    }
}

.nav-sections {
    background: transparent;
    margin: 0;
    z-index: 9;
    .navigation {
        background: transparent;
        ul {
            padding: 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: center;
            .level0 {
                margin: 0 41px;
                >.level-top {
                    font-family: $font_khandsemibold;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    color: #262424;
                    padding: 11px 0;
                    &:hover,
                    &.ui-state-focus,
                    &.ui-state-active {
                        color: #637497;
                    }
                }
                &.active>.level-top,
                &.has-active>.level-top {
                    border-width: 0;
                    color: #637497;
                }
                .submenu {
                    background: #132240;
                    border: 0;
                    box-shadow: none;
                    font-weight: 400;
                    padding: 0;
                    z-index: 99;
                    a {
                        font-family: $font_khandsemibold;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        &:hover,
                        &.ui-state-focus,
                        &.ui-state-active {
                            color: #fff;
                            background: #637497;
                        }
                    }
                    .active {
                        >a {
                            border-width: 0;
                            color: #fff;
                            background: #637497;
                        }
                    }
                    li {
                        &.parent {
                            >a {
                                >.ui-menu-icon {
                                    right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width:992px) {
    .navigation {
        .level0.parent {
            >.level-top {
                >.ui-menu-icon {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width:1375px) {
    .nav-sections {
        .navigation {
            ul {
                .level0 {
                    margin: 0 30px;
                }
            }
        }
    }
}

@media screen and (max-width:1199px) {
    .page-header {
        .header.content {
            .block-search {
                width: 230px;
            }
            #switcher-currency {
                margin: 10px 0 0 10px;
            }
        }
    }
    .nav-sections {
        .navigation {
            ul {
                .level0 {
                    margin: 0 17px;
                }
            }
        }
    }
}

@media screen and (max-width:991px) {
    .page-header {
        position: relative;
        .header.content {
            position: initial;
            padding: 16px 30px;
            justify-content: flex-start;
            align-items: center;
            &::before,
            &::after {
                display: none;
            }
            .logo {
                order: 1;
                margin: 0;
                img {
                    max-width: 154px;
                }
            }
            .header-contact {
                order: 2;
                width: auto;
                text-align: left;
                position: relative;
                bottom: auto;
                margin-left: auto;
                right: auto;
                .header-contact-detail {
                    display: block;
                }
                .header-contact-no {
                    a {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
                .header-contact-hours {
                    line-height: 20px;
                    margin-left: 0px;
                }
            }
            .block-search {
                width: auto;
                order: 3;
                position: initial;
                margin-left: 58px;
                .label {
                    display: inline-block;
                    float: none;
                    clip: unset;
                    height: auto;
                    margin: 0;
                    overflow: visible;
                    padding: 0;
                    position: relative;
                    width: auto;
                    &:before {
                        content: "";
                        background: url(../images/search-icon.png);
                        width: 22px;
                        height: 22px;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
                input {
                    padding: 5px 40px 5px 20px;
                    border: 0;
                }
                .control {
                    border-top: 0;
                    margin: 0;
                    padding: 15px;
                    position: absolute;
                    width: 100%;
                    background: #20386a;
                    display: block;
                    left: -300%;
                }
                .label.active+.control {
                    top: 100%;
                    left: 0;
                }
                .label.active+.control input {
                    position: relative;
                    left: auto;
                }
                .action.search {
                    display: none;
                }
            }
            .header-custom-links {
                order: 4;
                margin: 0 48px;
                ul {
                    li {
                        &.seprator,
                        &.accountsec-register {
                            display: none;
                        }
                        a {
                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
            .minicart-wrapper {
                margin: 0px;
                order: 5;
            }
            #switcher-currency {
                display: none;
            }
            .nav-toggle {
                left: auto;
                position: relative;
                top: auto;
                z-index: 14;
                order: 6;
                margin-left: 64px;
                &:before {
                    content: "";
                    background: url(../images/menu.png);
                    width: 34px;
                    height: 29px;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
    .nav-sections {
        transition: right 0.3s;
        height: 100%;
        right: -80%;
        left: calc(-1 * (100% - 54px));
        top: 0;
        width: 80%;
        width: calc(100% - 54px);
        background: #fff;
        .nav-sections-item-title {
            background: #20386a;
            border: solid #20386a;
            border-width: 0;
            a {
                color: #fff;
                text-transform: uppercase;
            }
            &.active {
                background: transparent;
                a {
                    color: #20386a;
                }
            }
        }
        .nav-sections-item-content {
            padding: 25px 20px;
        }
        .switcher {
            border-top: 1px solid #262424;
            font-family: $font_khandsemibold;
            font-weight: normal;
            font-size: 16px;
            line-height: 26px;
            color: #262424;
            padding: 11px 0;
            &:last-child {
                border-bottom: 1px solid #262424;
            }
            .switcher-dropdown {
                display: flex;
                padding: 0;
                align-items: center;
                li {
                    margin: 0;
                    padding: 0 2px;
                    a {
                        font-family: Arial;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 30px;
                        text-align: center;
                        color: #464646;
                        width: 26px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid transparent;
                        border-radius: 100%;
                        &:hover {
                            border: 2px solid #FFBE5F;
                        }
                    }
                    &.active {
                        a {
                            border: 2px solid #FFBE5F;
                        }
                    }
                }
            }
        }
        .header.links {
            border-bottom: 1px solid #262424;
            li {
                &.greet.welcome {
                    display: none;
                }
                >a {
                    border-top: 1px solid #262424;
                }
                a {
                    font-family: $font_khandsemibold;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #262424;
                    padding: 11px 0;
                    &:hover {
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 26px;
                        color: #637497;
                        padding: 11px 0;
                    }
                }
            }
        }
        .navigation {
            max-width: 100%;
            padding: 0;
            ul {
                >.level0 {
                    &:last-child {
                        border-bottom: 1px solid #262424;
                    }
                }
                .level0 {
                    margin: 0;
                    width: 100%;
                    position: relative;
                    border-top: 1px solid #262424;
                    &.active>a:not(.ui-state-active) span:not(.ui-menu-icon),
                    &.has-active>a:not(.ui-state-active) span:not(.ui-menu-icon) {
                        margin-left: 0;
                    }
                    &.active .all-category .ui-state-focus {
                        border: 0;
                    }
                    a {
                        text-transform: capitalize;
                    }
                    .submenu {
                        background: transparent;
                        padding: 0 0 15px 20px;
                        &:not(:first-child) .level1.active>a {
                            padding-left: 15px;
                        }
                        .submenu {
                            padding: 0 0 0 20px;
                        }
                        >li {
                            width: 100%;
                        }
                        a {
                            color: #262424;
                            &:hover,
                            &.ui-state-focus,
                            &.ui-state-active {
                                color: #637497;
                                background: transparent;
                            }
                        }
                        .active {
                            >a {
                                border-width: 0;
                                color: #637497;
                                background: transparent;
                            }
                        }
                    }
                }
            }
            .parent {
                .level-top {
                    &:after {
                        position: absolute;
                        right: 0;
                        top: auto;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .nav-open {
        .nav-sections {
            right: 0;
            left: auto;
        }
        .nav-open .page-wrapper {
            right: 80%;
            right: calc(100% - 54px);
            left: auto;
        }
    }
    .nav-before-open {
        .page-wrapper {
            transition: right 0.3s;
            right: 0;
            left: auto;
        }
    }
}

@media screen and (max-width:767px) {
    .cms-home {
        .page-header {
            border-bottom: 0;
        }
    }
    .page-header {
        border-bottom: 1px solid #E9EBF0;
        margin-bottom: 0;
        .header.content {
            padding: 13px 20px;
            .header-contact,
            .block-search,
            .header-custom-links {
                display: none;
            }
            .logo {
                img {
                    max-width: 110px;
                }
            }
            .minicart-wrapper {
                margin-left: auto;
                position: inherit;
                .update-cart-item {
                    float: none;
                }
                .action.showcart {
                    position: relative;
                }
                .block-minicart {
                    margin-top: 0;
                    .product {
                        .actions {
                            margin: 10px 0 0;
                        }
                    }
                }
            }
            .nav-toggle {
                margin-left: 52px;
            }
            .header-contact .header-contact-no a {
                font-size: 16px;
                line-height: 20px;
            }
            .header-contact-hours {
                font-size: 12px;
                line-height: 18px;
            }
        }
    }
}