.account {
    input {
        border: 1px solid #CED1D7;
        box-sizing: border-box;
        border-radius: 5px;
        height: 48px;
    }
    select {
        border: 1px solid #CED1D7;
        box-sizing: border-box;
        border-radius: 5px;
        height: 48px;
    }
    input[type='checkbox'] {
        height: auto;
    }
    .account-top{
        .order-status {
            margin-top: 20px;
        }
        .order-date{
            margin: 15px 0 !important;
        }
    }
    .block-collapsible-nav {
        .content {
            padding: 0;
            background: #E9EBF0;
            border-radius: 10px;
        }
        .item.current {
            a {
                border-color: transparent;
            }
        }
        .item {
            padding-left: 28px;
            padding-right: 27px;
            &:first-child {
                background: #637497;
                border-radius: 10px 10px 0px 0px;
                a {
                    color: #fff;
                    font-size: 24px;
                    line-height: 37px;
                    font-family: $font_khandmedium;
                    padding-top: 5px !important;
                    padding-bottom: 6px;
                    background: none;
                    border-bottom: none;
                    &:hover {
                        background: none;
                    }
                }
                strong {
                    color: #fff;
                }
            }
            &:nth-child(2n) {
                a {
                    border-bottom: 1px solid #D0D2D9;
                }
            }
            &:last-child {
                a {
                    border-top: 1px solid #D0D2D9;
                }
            }
            a {
                color: #20386A;
                font-size: 16px;
                line-height: 58px;
                font-family: $font_khandsemibold;
                padding: 0px;
                &:hover {
                    background: none;
                }
            }
            strong {
                color: #20386A;
                font-size: 16px;
                line-height: 58px;
                font-family: $font_khandsemibold;
                border-left: none;
                padding: 0px;
            }
            .delimiter {
                display: none;
            }
        }
    }
    .form-edit-account {
        margin-top: 24px;
    }
    .form-address-edit {
        margin-top: 24px;
    }
    .block-addresses-default {
        margin-top: 24px;
        .block-content {
            .box-actions {
                .action {
                    width: 208px;
                    &:after {
                        content: none !important;
                    }
                }
            }
        }
    }
    .block-dashboard-info {
        margin-bottom: 43px;
        margin-top: 24px;
        .box-information {
            .box-title {
                display: none !important;
            }
            .box-content {
                p {
                    color: #515050;
                    line-height: 22px;
                }
            }
        }
    }
    .block-dashboard-addresses {
        margin-bottom: 24px;
        border-bottom: 1px solid #E9EBF0;
        padding-bottom: 35px;
        .block-title {
            .action {
                display: none;
            }
        }
        .block-content {
            margin-bottom: 8px;
            .box-title {
                line-height: 28px;
                color: #262424;
                margin-bottom: 16px !important;
            }
            a {
                font-size: 16px;
                line-height: 22px;
                color: #637497;
            }
        }
    }
    .block-dashboard-orders {
        .block-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px !important;
            padding: 0px;
            .action {
                width: 188px;
                height: 48px;
                border: 3px solid #FFBE5F;
                box-sizing: border-box;
                border-radius: 27.5px;
                color: #262424 !important;
                font-size: 18px;
                line-height: 24px;
                font-family: $font_khandregular;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    background-color: #ffbe5f;
                }
            }
        }
        table {
            thead {
                tr {
                    th {
                        padding: 3px 10px;
                        line-height: 46px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        padding: 3px 10px;
                        line-height: 46px;
                        a {
                            color: #637497;
                            text-decoration: underline;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
    }
    .block-content {
        .box-actions {
            display: flex;
            margin-top: 16px;
            .action {
                width: 188px;
                height: 48px;
                border: 3px solid #FFBE5F;
                box-sizing: border-box;
                border-radius: 27.5px;
                color: #262424 !important;
                font-size: 18px;
                line-height: 24px;
                font-family: $font_khandregular;
                position: relative;
                display: flex;
                margin-right: 32px;
                align-items: center;
                padding-left: 25px;
                &:after {
                    content: "" !important;
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    background-image: url('../images/pen.svg');
                    background-repeat: no-repeat;
                    width: 21px;
                    height: 21px;
                    transform: translateY(-50%);
                    border-left: none;
                    margin-right: 14px;
                    display: inline-block;
                }
                &:hover {
                    background-color: #ffbe5f;
                }
            }
        }
    }
    .block-title {
        margin: 0 !important;
        border: none !important;
        strong {
            border-bottom: none;
            color: #20386A;
            font-size: 24px !important;
            font-family: $font_khandsemibold;
            line-height: 37px;
        }
    }
    .account-top {
        width: 77.7%;
        float: right;
        padding-left: 16px;
        .page-title {
            font-size: 40px;
            line-height: 58px;
            color: #20386a;
            font-family: $font_khandlight;
            border-bottom: 1px solid #e9ebf0;
            margin-bottom: 0;
            margin-top: 23px;
            padding-bottom: 21px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1375px) {
    .account {
        .block-content {
            .box-actions {
                .action {
                    font-size: 16px;
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .account {
        .block-dashboard-info {
            .block-content {
                .box-information {
                    width: 100% !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .account {
        .account-top {
            width: 100%;
            float: none;
            padding: 0;
            .page-title {
                margin-top: 0px;
            }
        }
        .block-collapsible-nav {
            left: inherit;
            position: inherit;
            top: inherit;
            .title {
                display: none;
            }
            .content {
                display: block;
            }
        }
        .sidebar-main {
            order: -1;
        }
    }
}