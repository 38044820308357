.home_featured_products {
    margin: 100px 0px 80px;
}

.featured_product_list {
    .block-products-list {
        .block-title {
            text-align: center;
            margin-bottom: 32px;
            strong {
                text-align: center;
                color: #20386A;
                font-size: 32px;
                line-height: 58px;
                text-transform: uppercase;
                font-family: $font_khandsemibold;
            }
        }
        .products-grid {
            .product-items {
                margin: 0px -28px;
                display: flex;
                flex-wrap: wrap;
                .product-item {
                    width: calc(100% / 4);
                    margin: 0px 0px 45px !important;
                    padding: 0px 16px;
                    height: auto;
                    .product-item-info {
                        height: 100%;
                        max-width: 304px;
                        margin: 0px auto;
                        &:hover {
                            background: transparent;
                            box-shadow: none;
                            border: none;
                            margin: 0px auto;
                            padding: 0px;
                            position: relative;
                            z-index: inherit;
                        }
                        .product-item-photo {
                            width: 100%;
                            display: block;
                            text-align: center;
                            .product-image-container {
                                width: 100% !important;
                                display: block;
                                .product-image-wrapper {
                                    padding-bottom: 236px !important;
                                    .product-image-photo {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                        .product-item-details {
                            min-height: calc(100% - 236px);
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            padding: 18px 12px 0px;
                            .product-item-name {
                                text-align: center;
                                margin: 0px 0px 8px;
                                .product-item-link {
                                    color: #20386A;
                                    font-size: 28px;
                                    line-height: 34px;
                                    font-family: $font_khandregular;
                                    text-decoration: none;
                                    &:hover {
                                        color: #ffbe5f;
                                    }
                                }
                            }
                            .used-for-section {
                                margin: auto 0px 8px;
                                .used-for-inner {
                                    color: #637497;
                                    font-size: 18px;
                                    line-height: 28px;
                                    text-align: center;
                                    font-family: $font_primary;
                                }
                            }
                            .price-box {
                                text-align: center;
                                margin: 0px 0px 8px;
                                .price {
                                    color: #262424;
                                    font-size: 32px;
                                    line-height: 58px;
                                    font-family: $font_khandbold;
                                }
                            }
                            .product-item-inner {
                                display: block !important;
                                position: inherit;
                                height: auto;
                                width: auto;
                                margin: 0px;
                                box-shadow: none;
                                border: none;
                                padding: 0px;
                                overflow: visible;
                                .product-item-actions {
                                    margin: 0px;
                                    .actions-primary {
                                        width: 100%;
                                        display: block;
                                        .tocart {
                                            border: 3px solid #ffbe5f;
                                            border-radius: 27.5px;
                                            width: 100%;
                                            color: #262424;
                                            background-color: transparent;
                                            font-size: 20px;
                                            line-height: 24px;
                                            padding: 12px 15px;
                                            transition: all ease 0.5s;
                                            &:hover {
                                                background-color: #262424;
                                                color: #ffbe5f;
                                            }
                                        }
                                    }
                                    .actions-secondary {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.view-all {
    text-align: center;
    a {
        color: #637497;
        font-size: 18px;
        line-height: 58px;
        text-transform: uppercase;
        text-decoration: underline;
        font-family: $font_khandsemibold;
        &:hover {
            color: #ffbe5f;
            text-decoration: underline;
        }
    }
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .home_featured_products {
        margin: 68px 0 40px;
    }
    .featured_product_list {
        .block-products-list {
            .products-grid {
                .product-items {
                    margin: 0px auto;
                    max-width: 640px;
                    .product-item {
                        width: 50%;
                        &:first-child,
                        &:nth-child(2) {
                            margin: 0 0 75px!important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) {
    .home_featured_products {
        margin: 57px 0 50px;
    }
    .featured_product_list {
        .block-products-list {
            .products-grid {
                .product-items {
                    margin: 0px auto;
                    max-width: 304px;
                    .product-item {
                        width: 100%;
                        margin: 0 0 32px!important;
                        padding: 0px;
                        &:first-child,
                        &:nth-child(2) {
                            margin: 0 0 32px!important;
                        }
                        .product-item-info {
                            .product-item-details {
                                padding: 18px 0px 0;
                                min-height: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}