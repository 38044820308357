.home-image-title-slider-main {
    margin: 30px 0;
    .slick-list {
        margin: 0 -29px;
    }
    .slick-track {
        display: flex;
        flex-wrap: wrap;
        .slick-slide {
            float: none;
            height: auto;
        }
    }
    .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        svg {
            display: block;
        }
        &.slick-prev {
            left: -112px;
        }
        &.slick-next {
            right: -112px;
        }
    }
    .hits-list {
        max-width: 1086px;
        margin: 0px auto;
    }
    .hits-item {
        padding: 0 29px;
        a {
            text-decoration: none;
            display: block;
            width: 100%;
            height: 100%;
        }
        .hits-wrap {
            border: 1px solid rgba(32, 56, 106, .5);
            display: block;
            padding: 59px 69px 35px;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-repeat: repeat;
            background-position: 0 0;
            background-image: url('../images/dots-bg.png');
            background-color: transparent;
            transition: all ease 0.3s;
            img {
                display: block;
                margin: auto;
            }
            .hits-title {
                font-family: $font_khandlight;
                font-size: 48px;
                line-height: 1.2083;
                color: #20386A;
                text-align: center;
                margin: 42px 0 0;
                display: block;
                transition: all ease 0.3s;
            }
            &:hover {
                background-color: #061027;
                .hits-title {
                    color: #FFFFFF;
                }
            }
        }
    }
}

@media (max-width: 1366px) {
    .home-image-title-slider-main {
        .slick-arrow {
            &.slick-prev {
                left: -45px;
            }
            &.slick-next {
                right: -45px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .home-image-title-slider-main {
        .hits-item {
            padding: 0 16px;
        }
        .slick-list {
            margin: 0 -16px;
        }
    }
}

@media (max-width: 991px) {
    .home-image-title-slider-main {
        margin: 24px 0;
        .slick-arrow {
            display: none !important;
        }
    }
}

@media (max-width: 767px) {
    .home-image-title-slider-main {
        margin: 50px 0;
        .hits-item {
            padding: 0px;
            margin-bottom: 40px;
        }
    }
}