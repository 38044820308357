.home-engine-parts-main {
    margin: 32px 0;
    .hep-title {
        font-family: $font_khandsemibold;
        font-weight: normal;
        font-size: 32px;
        line-height: 1.8125;
        color: #20386A;
        text-align: center;
        margin: 0;
    }
    .hep-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -29px;
        .hep-item {
            width: 25%;
            padding: 29px;
            a {
                display: block;
                width: 100%;
                height: 100%;
            }
            .hep-wrap {
                display: block;
                position: relative;
                width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.16);
                padding-top: calc(100% - 2px);
                img {
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    max-width: calc(100% - 30px);
                    max-height: calc(100% - 30px);
                }
            }
        }
    }
}

@media (max-width: 1199px) {
    .home-engine-parts-main {
        .hep-list {
            margin: 0 -20px;
            .hep-item {
                padding: 20px;
            }
        }
    }
}

@media (max-width: 991px) {
    .home-engine-parts-main {
        margin: 24px 0;
        .hep-list {
            .hep-item {
                width: 50%;
            }
        }
    }
}

@media (max-width: 767px) {
    .home-engine-parts-main {
        margin: 51px 0;
        .hep-title {
            margin: 0 0 8px;
        }
        .hep-list {
            margin: 0;
            padding: 0 26px;
        }
    }
}

@media (max-width: 575px) {
    .home-engine-parts-main {
        .hep-list {
            .hep-item {
                width: 100%;
                padding: 20px 0;
            }
        }
    }
}