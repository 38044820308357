.customer-account-login,
.customer-account-logoutsuccess,
.customer-account-forgotpassword,
.customer-account-createpassword {
    .column.main {
        padding-bottom: 106px;
    }
    .page-main {
        >.page-title-wrapper {
            .page-title {
                color: #20386a;
                font-size: 40px;
                line-height: 1.45;
                font-family: $font_khandregular;
                margin: 32px 0 30px 0;
            }
        }
        .block {
            &,
            p {
                font-size: 16px;
                line-height: 1.375;
                color: #515050;
            }
            a {
                font-family: $font_khandregular;
                color: #20386a;
                &:hover {
                    color: #ffbe5f;
                }
            }
            .block-title {
                color: #20386a;
                font-size: 24px;
                line-height: 1.42;
                font-family: $font_khandsemibold;
                margin: 0 0 20px 0;
                border: 0;
                padding: 0;
            }
        }
        .fieldset {
            >.field {
                >.control {
                    // width: 100%;
                }
                input[type="text"],
                input[type="password"],
                input[type="url"],
                input[type="tel"],
                input[type="search"],
                input[type="number"],
                input[type="datetime"],
                input[type="email"] {
                    border: 1px solid #CED1D7;
                    border-radius: 5px;
                    font-family: $font_open_sansitalic;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 26px;
                    color: #909090;
                    padding: 11px 14px;
                    height: auto;
                }
            }
            &,
            >.fields {
                >.field {
                    margin: 0 0 24px;
                    .label {
                        color: #20386a;
                        font-size: 14px;
                        line-height: 1.86;
                        font-family: $font_open_sanssemibold;
                        font-weight: normal;
                        margin: 0;
                    }
                }
            }
        }
        .actions-toolbar {
            margin-top: 72px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            &::before,
            &::after {
                display: none;
            }
            .action {
                clear: both;
                float: none;
                &.primary {
                    padding: 13px 29px;
                    max-width: 100%;
                    width: 280px;
                }
            }
            .primary {
                margin: 0 15px 0 0;
            }
            .secondary {
                a.action {
                    margin: 10px 0;
                }
            }
        }
    }
}