.wordpress-post-view , .cms-further-page {
    .page-main {
        width: 100%;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.post-view-blog , .cms-page-view {
    .banner-block {
        margin-bottom: 57px;
        .post-image {
            margin: 0px;
            .post-image-inner {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
        img {
            width: 100%;
        }
    }
    .intro-section {
        color: #262424;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 38px;
        font-family: $font_open_sansbold;
        .page-title {
            h1 {
                margin-bottom: 24px;
                color: #20386A;
                font-size: 40px;
                line-height: 58px;
                font-family: $font_primary;
            }
        }
    }
    .content-part {
        margin: 64px 0px 74px;
        &.first{
            margin-top: 38px;
        }
        .title {
            color: #20386A;
            font-size: 32px;
            line-height: 58px;
            margin-bottom: 24px;
        }
    }
    .general-detail-inner {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 28px;
        .left-part {
            .post-name {
                h1 {
                    margin-bottom: 0px;
                    color: #20386A;
                    font-size: 40px;
                    line-height: 58px;
                }
            }
            .meta-detail {
                display: inline-flex;
                flex-wrap: wrap;
                color: #637497;
                font-size: 18px;
                line-height: 18px;
                text-transform: capitalize;
                padding: 16px 0px;
                .post-user {
                    border-right: 1px solid #637497;
                    margin-right: 10px;
                    padding-right: 10px;
                }
            }
        }
        .right-part {
            margin-top: 20px;
        }
    }
    .full-image-section {
        margin: 25px 0;
        .image-section {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            img {
                width: 100%;
            }
        }
    }
    .accordion-section{
        margin: 74px 0 119px;
        .accordion-title-main {
            color: #20386A;
            font-size: 32px;
            line-height: 46px;
            margin: 0 0 40px;
            font-weight: normal;
            font-family: $font_khandregular;
        }
        .accordion-container {
            border-bottom: 1px solid #E9EBF0;
            .accordion-title {
                position: relative;
                font-family: $font_khandsemibold;
                font-weight: normal;
                font-size: 20px;
                line-height: 30px;
                color: #20386A;
                cursor: pointer;
                padding: 20px 0;
                border-top: 1px solid #E9EBF0;
                margin: 0;
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 15px;
                    height: 15px;
                    background-image: url(../images/plus.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                &.open{
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        width: 15px;
                        height: 3px;
                        background-image: url(../images/minus.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
            .accordion-content {
                margin: 4px 0 24px;
                p{
                    font-size: 16px;
                    line-height: 30px;
                    color: #262424;
                    margin: 0 0 30px;
                }
                h6{
                    font-size: 18px;
                    line-height: 30px;
                    color: #262424;
                    margin: 0 0 30px ;
                    padding: 30px 0 0;
                }
                ul , ol{
                    margin: 24px 0;
                    padding-left: 20px;
                    li{
                        font-size: 16px;
                        line-height: 30px;
                        color: #545454;
                        margin: 0 0 6px;
                    }
                }                
            }
        }
    }  
    .double-image-section {
        .double-image-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .image-section {
                width: calc(50% - 16px);
                .image-bg {
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    .testimonial-section {
        background: #E9EBF0;
        padding: 59px 0;
        text-align: center;
        .testimonial-section-wrapper {
            max-width: 754px;
            margin: 0 auto;
            .testimonial-quote {
                margin: 0 0 25px;
            }
            .testimonial-text {
                font-size: 20px;
                line-height: 40px;
                color: #262424;
                margin: 0 0 28px;
            }
            .testimonial-name {
                font-family: $font_open_sansitalic;
                font-weight: 300;
                font-size: 16px;
                line-height: 30px;
                text-align: center;
                color: #637497;
            }
        }
    }
    .logo-section {
        margin: 70px 0;
        .hep-title {
            text-transform: uppercase;
        }        
        .hep-list {
            margin: 0 !important;
            padding: 0 65px;
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            &.slick-next {
                right: 0;
            }
            &.slick-prev {
                left: 0;
            }
        }
    }
    .full-center-main{
        margin: 70px 0 80px;
        .full-center-title {
            font-family: $font_khandsemibold;
            font-size: 32px;
            line-height: 1.8125;
            color: #20386a;
            text-align: center;
            margin: 0 0 29px;
            text-transform: uppercase;
            font-weight: normal;
        }
        .full-center-slider{
            .slick-slide {
                padding: 0 16px;
            }
        }
        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            &.slick-next {
                right: 50px;
            }
            &.slick-prev {
                left: 50px;
            }
        }
    }
    .left-right-image-section {
        margin: 80px 0;
        .left-right-image-item {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .image{
                width: 50%;
                display: flex;
                img{
                    width: 100%;
                    min-height: 380px;
                }
            }
            .data{
                width: 50%;
                padding: 20px 118px 20px 60px;
                .title{
                    font-family: $font_khandlight;
                    font-weight: normal;
                    font-size: 32px;
                    line-height: 49px;
                    color: #20386A;
                    margin: 0 0 29px;
                }
                p{
                    margin: 0;
                }
            }
            &.right{
                .image{
                    order: 2;
                }
                .data{
                    padding: 20px 60px 20px 118px;
                }
            }
        }
    }
    .social-backto-top {
        .social-backto-inner {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .backto-top {
                button {
                    color: #637497;
                    padding: 0px;
                    background-color: transparent;
                    text-decoration: underline;
                    font-size: 20px;
                    line-height: 24px;
                    font-family: $font_khandregular;
                    &:hover {
                        color: #ffbe5f;
                    }
                }
            }
        }
    }
    
    .blog-share-block {
        .addthis_toolbox {
            display: inline-flex;
            align-items: center;
            flex-wrap: wrap;
            .at300b {
                margin: 0px 35px 0px 0px;
                &:hover{
                    opacity: 0.7;
                }
                &:last-child {
                    margin: 0px;
                }
            }
        }
    }
}

.recent-post-section{
    margin: 96px 0 10px;
    .block-title{
        font-family: $font_khandregular;
        font-weight: 400;
        font-size: 32px;
        line-height: 58px;
        color: #20386a;
        margin: 0 0 30px;
        text-align: center;
        display: block;
    }
    .post-list-wrapper {
        .post-list {
            li {
                width: 25% !important;
                .post-meta{
                    h2{
                        margin: 0 0 5px !important;
                    }
                }
            }
        }
    }
}

.lity-iframe-container{    
    width: 60vw;
} 
body button:not(.primary){
    &.lity-close {
        z-index: 9994;
        width: 35px;
        height: 35px;
        position: fixed;
        right: 0;
        top: 0;
        -webkit-appearance: none;
        cursor: pointer;
        text-decoration: none;
        text-align: center;
        padding: 0;
        color: #fff;
        font-style: normal;
        font-size: 35px;
        font-family: Arial,Baskerville,monospace;
        line-height: 35px;
        text-shadow: 0 1px 2px rgba(0,0,0,.6);
        border: 0;
        background: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #20386a;
        color: #fff;
        border: 0;
    }
}

.video-text-section{
    .video-text-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        .video-text-left {
            width: 58.8%;
            a{
                position: relative;
                display: block;
                &::before{
                    content: "";
                    background: url(../images/play.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    width: 100%;
                    height: 100%;
                    display: block;
                    z-index: 0;
                    position: absolute;
                }
            }
            img{
                width: 100%;
                min-height: 435px;
            }
        }
        .video-text-right {
            width: 100%;
            background: #e9ebf0;
            padding: 52px 72px 52px 46px;
            max-width: 528px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            h4{
                font-weight: bold;
                font-size: 16px;
                line-height: 28px;
                color: #262424;
                margin: 0 0 24px;
            }
            p{
                font-size: 16px;
                line-height: 30px;
                color: #262424;
                margin: 0;
            }
        }
    }
}


@media screen and (max-width:1199px){
    .post-view-blog , .cms-page-view{
        .full-center-main {
            .full-center-slider{
                .slick-slide {
                    max-width: 800px;
                }
            }
        }
        .left-right-image-section {
            .left-right-image-item {
                .data{
                    padding: 20px;
                    p {
                        font-size: 14px;
                        line-height: normal;
                    }                    
                }
                &.right{
                    .data{
                        padding: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width:991px){
    .recent-post-section{
        .post-list-wrapper {
            .post-list {
                li {
                    width: 50% !important;
                }
            }
        }
    }
    .video-text-section {
        .video-text-wrapper {
            .video-text-right {
                padding: 34px 46px;
                max-width: 400px;
            }
        }
    }
    .intro-section {
        .page-title {
            h1 {
                font-size: 34px;
                line-height: 44px;
            }
        }
    }
    .post-view-blog , .cms-page-view {
        .full-center-main {
            .full-center-slider{
                .slick-slide {
                    max-width: 700px;
                }
            }
        }
        .general-detail-inner {
            .left-part{
                .post-name {
                    h1 {
                        font-size: 34px;
                        line-height: 44px;
                    }
                }
            }
            .right-part {
                margin-top: 15px;
            }
        }
        .left-right-image-section {
            .left-right-image-item {
                .data{
                    .title{
                        margin: 0 0 10px;
                    }                    
                }
            }
        }
    }
}

@media screen and (max-width:767px){
    .recent-post-section{
        margin: 40px 0 0;
    }
    .post-view-blog , .cms-page-view{
        .banner-block{
            img {
                min-height: 150px;
            }
        }
        .full-center-main {
            .full-center-slider{
                .slick-slide {
                    padding: 0 6px;
                    max-width: 550px;
                }
            }
        }
        .testimonial-section{
            padding: 40px 0;
            .testimonial-section-wrapper{
                .testimonial-text {
                    font-size: 18px;
                    line-height: 36px;
                }
            }
        }
        .banner-block {
            margin-bottom: 24px;
        }
        .full-image-section {
            margin: 10px 0;
        }
        .intro-section {
            .page-title {
                h1 {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
        }
        .general-detail-inner{
            .right-part {
                width: 100%;
            }
            .left-part{
                .post-name {
                    h1 {
                        font-size: 28px;
                        line-height: 38px;
                    }
                }
            }
        }
        .intro-section {
            margin-bottom: 30px;
        }
        .content-part {
            margin: 30px 0;
            &.first {
                margin-top: 30px;
            }
        }
        .logo-section {
            margin: 40px 0;    
            .hep-list {
                margin: 0 !important;
                padding: 0;
            }
        }
        .full-center-main{
            margin: 40px 0;
        }
        .left-right-image-section {
            margin: 30px 0;
            .left-right-image-item {
                .image{
                    width: 100%;
                    img{
                        min-height: 100%;
                    }
                }
                .data{
                    width: 100%;
                    padding: 20px 0 40px;
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                &.right{
                    .image {
                        order: 1;
                    }
                    .data{
                        order: 2;
                        padding: 20px 0 40px;
                    }
                }
                &:last-child{
                    .data{
                        padding-bottom: 0 !important;
                    }
                }
            }
        }
        .social-backto-top {
            .social-backto-inner{
                .social-share {
                    width: 100%;
                    margin: 0 0 20px;
                }
            }
        }
        .double-image-section {
            .double-image-inner {
                .image-section {
                    width: 100%;
                    margin: 0 0 10px;
                    &:last-child{
                        margin: 0;
                    }
                }
            }
        }
        .accordion-section{
            margin: 40px 0 60px;
            .accordion-container {
                .accordion-content {
                    margin: 4px 0 24px;
                    p{
                        margin: 0 0 20px;
                    }
                    h6{
                        margin: 0 0 20px ;
                        padding: 20px 0 0;
                    }
                    ul , ol{
                        margin: 24px 0;
                        padding-left: 20px;
                    }                
                }
            }
        }
    }
    .video-text-section{
        .video-text-wrapper {
            .video-text-left {
                width: 100%;
                a{
                    display: flex;
                }
                img{
                    width: 100%;
                    min-height: 100%;
                }
            }
            .video-text-right {
                padding: 34px 25px;
                max-width: 100%;
                position: relative;
                top: auto;
                transform: none;
                right: auto;
            }
        }
    }
}

@media screen and (max-width:575px){
    .cms-page-view , .post-view-blog {
        .full-center-main {
            .full-center-slider{
                .slick-slide {
                    padding: 0 3px;
                    max-width: 300px;
                }
            }
        }
        .logo-section{
            &.home-engine-parts-main {
                .hep-list {
                    .hep-item {
                        padding: 20px 0;
                    }
                }
            }
        }
    }
    .recent-post-section{
        .post-list-wrapper {
            .post-list {
                li {
                    width: 100% !important;
                }
            }
        }
    }
}