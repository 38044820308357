.footer-main {
    background: #132240;
    padding: 58px 0 0;
    .footer-data {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 0 26px;
        margin: 0 0 30px;
        border-bottom: 1px solid #20386A;
        .footer-data-image {
            width: 20%;
        }
        .footer-data-text {
            width: 69%;
            p{
                font-family: $font_khandregular;
                font-size: 18px;
                line-height: 26px;
                color: #FFFFFF;
            }
        }
    }
    .footer-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .footer-menu {
            width: 33.33%;
            text-align: center;
            h2{
                font-family: $font_khandsemibold;
                font-weight: normal;
                font-size: 14px;
                line-height: 26px;
                text-transform: uppercase;
                color: #C2D8F8;
                margin: 0 0 22px;
            }
            ul{
                margin: 0;
                padding: 0;
                list-style: none;
                li{
                    margin: 0 0 10px;
                    a{
                        font-family: $font_primary;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 26px;
                        color: #E9EBF0;
                        text-decoration: none;
                        transition: all ease .4s;
                        &:hover{
                            text-decoration: none;
                            color: #C2D8F8;
                        }
                    }
                }
            }
        }        
    }
    .footer-social {
        margin: 32px 0 0;
        ul{
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            list-style: none;
            li{
                margin: 0 16px 0 0 ;
                padding: 0;
                a {
                    width: 36px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all ease .4s;
                    border-radius: 5px;
                    &:hover{
                        background:#637497;
                    }
                }
                &:last-child{
                    margin: 0;
                }
            }
        }
    }
}
.copyright {
    background-color: #132240;
    padding: 48px 15px 55px;
    text-align: center;
    font-family: Helvetica;
    font-size: 12px;
    line-height: 26px;
    text-align: center;
    color: #E9EBF0;
}

@media screen and (max-width:1199px){
    .footer-main{
        .footer-data {
            .footer-data-text {
                width: 72%;
            }
        }
    }
}

@media screen and (max-width:991px){
    .footer-main{
        padding: 44px 0 0;
        .footer-data {
            justify-content: center;
            padding: 0 0 20px;
            .footer-data-image {
                width: 100%;
                text-align: center;
                margin:  0 0 28px;
                img{
                    max-width: 148px;
                }
            }
            .footer-data-text {
                width: 100%;
                text-align: center;
            }
        }
        .footer-links {
            .footer-menu {
                width: auto;
            }
        }
        .footer-social {
            margin: 34px 0 0;
        }
    }
    .copyright {
        padding: 20px 15px 48px;
    }
}

@media screen and (max-width:767px){
    .footer-main{
        padding: 38px 0 0;
        .footer-data {
            margin: 0 0 45px;
        }
        .footer-links {
            .footer-menu {
                width: 100%;
                h2{
                    position: relative;
                    text-align: left;
                    cursor: pointer;
                    &::after{
                        content: "";
                        background: url(../images/down-arrow.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                        display: block;
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 17px;
                        height: 11px;    
                        transition: all ease .4s;                
                    }
                    &.active{
                        &::after{
                            transform: translateY(-50%) rotate(180deg);                 
                        }
                    }
                }
                ul{
                    display: none;
                    text-align: left;
                    margin: 0 0 25px;
                }
            }
        }
        .footer-social {
            margin: 26px 0 0;
        }
    }
    .copyright {
        padding: 24px 15px 78px;
    }    
}